@import "../../../styles/variables";
.upload-container {
  width: 100%;
  margin-top: 2em;
  
  .MuiListItem-container {
    background-color: $primary-white;
    box-shadow: 0px 3px 6px $box-shadow-color;
    padding: 1em 0.5em;
    width: 100vw;
    margin-left: -3em;
  }

  .upload-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 1.5em;

    .btn-choose {
      margin-top: 1em;
    }

    .selected-files {
      width: 100%;

      .selected-file-item {
        margin: 1em 0;
        background-color: $primary-white;
        box-shadow: 0px 3px 6px $box-shadow-color;
        border-left: 5px solid $primary-blue;
        border-radius: 5px;
        width: 100%;
        padding: 1em;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .filename {
          width: 55%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .acceptable-file-types {
      padding-top: 20px;
      color: $primary-dark-blue;
      font-size: 14px;
    }
  }

  .list-header {
    color: $primary-light-gray;
    text-align: right;
    font-weight: bold;
  }

  .error {
    padding: 10px;
    color: red;
    font-size: 14px;
  }
}
