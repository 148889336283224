@import "../../../styles/variables";

.mobile-download-container {
  min-height: 500px;

  .mobile-download-header-container {
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .mobile-download-qrcode-container {
    width: 100%;
    text-align: center;
  }

  .mobile-download-button-container {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 30px;
    }
  }
}