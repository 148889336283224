@import "../../../../styles/variables";

.admin-menu {
    .MuiListItem-root.Mui-selected, .MuiListItem-button:hover, .MuiListItem-root.Mui-selected:hover {
        text-decoration: none;
        background-color: #DFEFF8;

        .basic-icon {
            color: #DFEFF8;
        }
    }

    .MuiListItemText-root {
        padding-left: 0;
    }

    .icon-box {
    color: $primary-gray;
    padding: 0em 1.5em 0em .5em;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    
    .MuiSvgIcon-root {
        width: 24px !important;
        height: 24px !important;
    }
    
    .personal-icon {
        color: $primary-black;
    }
    
    .MuiListItemText-root {
        margin-bottom: 0;
        color: #020606;
    
        span {
        font-size: 18px;
        }
    }
    
    .MuiListItemIcon-root {
        min-width: 36px;
    }
    
    .MuiSvgIcon-root {
        width: .8em;
        height: 1em;
    }
    
    .basic-icon {
        color:white;
    }
    
    &:hover {
        cursor: pointer;
    }
    }
    
    .blue-underline {
    color: $primary-blue;
    position: relative;
    }
    
    .blue-nounderline {
    color: $primary-blue;
    }
}
