.modal {
    &.view-user-data {
        &.recruiter {
            .modal-content {
                @media screen and (max-width: 640px) {
                    width: 100% !important;
                    height: 100% !important;
                }

                .recruiter-avatar {
                    margin-bottom: 1em;
                }
                .MuiInputLabel-outlined {
                    z-index: 0 !important;
                }

                .speciality-select {
                    [class*="control"] {
                        height: 56px;
                        overflow-y: scroll;
                        overflow: hidden;
                    }

                    [class*="ValueContainer"]{
                        overflow-y: scroll;
                        max-height: -webkit-fill-available;
                        overflow: hidden;
                    }
                }

                .footer-profile-modal {
                    position: sticky; 
                    bottom: 0;
                    left: 0; 
                    right: 0; 
                    display: flex;
                    width: 100%;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    background-color: #fff;
                    height: 3.8rem;
                    box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.16);
                    z-index: 100;
    
                    .save-button {
                        text-transform: initial;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 24px;
                        background: #008DD1;
                        border-radius: 32px;
                        justify-content: center;
                        min-width: 182px;
                        height: 40px;
                    }
    
                    .transparent-button {
                        text-transform: initial;
                        border: 1px solid #C2C4C4;
                        box-sizing: border-box;
                        border-radius: 32px;
                        color: #8D9292;
                        background-color: white;
                        font-size: 18px;
                        line-height: 24px;
                        font-weight: normal;
                        margin-right: 0.75em;
                        height: 40px;
                        width: 71px;
    
                        &:focus, &:hover, &:active {
                            text-decoration: none; 
                            text-underline-position: none; 
                            background-color: #c2c4c46b;
                        }
                    }
                }
            }
        }
        .modal-content {
            position: relative;
            width: 650px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 2em 0em 0 0em;
            overflow-y: auto;
            overflow-x: hidden;
            margin: auto;

            @media screen and (max-width: 640px) {
                width: 100% !important;
                height: 100% !important;
            }

            .speciality-select {
                [class*="control"] {
                    height: 56px;
                    overflow-y: scroll !important;
                    overflow: hidden;
                }

                [class*="ValueContainer"]{
                    overflow-y: scroll !important;
                    max-height: -webkit-fill-available;
                    overflow: hidden;
                }
            }

            h4 {
                align-self: flex-start;
                margin: 0 2em 1em;
            }

            .MuiContainer-root {
                padding-bottom: 50px;
            }

            .location-grid {
                position: relative;
          
                svg {
                  cursor: pointer;
                  position: absolute;
                  right: 10px;
                  top: 45px;
                }
          
                .MuiAutocomplete-endAdornment {
                  display: none;
                }
              }

            .years-options, .primary-speciality-options {
                display: contents;
            }

            .assigned-team {
                margin-top: 10px;
            }
    
            .footer-profile-modal {
                position: sticky; 
                bottom: 0;
                left: inherit; 
                right: inherit; 
                display: flex;
                width: 100%;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                background-color: #fff;
                height: 3.8rem;
                box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.16);
                z-index: 100;

                .save-button {
                    text-transform: initial;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 24px;
                    background: #008DD1;
                    border-radius: 32px;
                    justify-content: center;
                    min-width: 182px;
                    height: 40px;
                }

                .transparent-button {
                    text-transform: initial;
                    border: 1px solid #C2C4C4;
                    box-sizing: border-box;
                    border-radius: 32px;
                    color: #8D9292;
                    background-color: white;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: normal;
                    margin-right: 0.75em;
                    height: 40px;
                    width: 71px;

                    &:focus, &:hover, &:active {
                        text-decoration: none; 
                        text-underline-position: none; 
                        background-color: #c2c4c46b;
                    }
                }
            }
        }
    }
}
