@import "../../../styles/variables";
@import "../../../styles/common";

.card {
  display: block;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 3px #cccccc50;
  margin-bottom: 15px;
  .content {
    align-items: center;
    display: flex;
    flex: 1 1 0%;
    min-width: 0;
  }
  h6 {
    font-weight: 500;
  }
}
.grid-5 {
  display: grid;
  flex: 1 1 0%;
  gap: 1rem;
  grid-template-columns: repeat(5,minmax(0,1fr));
  min-width: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  .col-2 {
    grid-column: 1 / 3;
  }
}

.pill {
  background: #eee;
  display: inline-flex;
  border-radius: 50px;
  margin: 0 5px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  &.status {
    padding: 0.125rem 0.625rem;
    &.step-0 {
      background: #9905ea36
    }
    &.step-1 {
      background: #e7ea0566;
    }
    &.step-2 {
      background: #2bea0536;
    }
    &.step-3 {
      background: #05e2ea66;
    }
  }
}


svg.icon {
  transform: scale(0.6);
  fill: #aaa;
  margin-left: -6px;
}

.flex-center {
  display: flex;
  align-items: center;
}

.show-xs.icon {
  display: none;
}

.favorite {
  min-width: 30px;
}

@media screen and (max-width: 690px) {
  .show-xs .icon {
    display: block;
  }
  .grid-5 {
    grid-template-columns: repeat(1,minmax(0,1fr));
  }
}

// Hover and clickable events
@media screen and (min-width: 768px) {
  .btn:hover {
    -webkit-filter: brightness(1.1);
    filter: brightness(1.2);
    transition: all .2s ease-in-out;
  }
  .card.clickable {
    cursor: pointer;
    &:hover {
      background-color: $background-lightest-gray;
    }
    .grid-5:hover {
      text-decoration: underline;
    }
  }
  .favorite:hover {
    svg {
      color: red;
      transform: scale(1.1);
      transition: all .3s ease-in-out;
    }
  }
}

// Between 960 & 1080
@media screen and (min-width: 960px) and (max-width: 1079px) {
  .grid-5{
    grid-template-columns: repeat(4,minmax(0,1fr));
    & .col:last-of-type {
      display: none;
    }
  }
}
