@import "../../../styles/variables";

.progress {
  border-radius: 50px;
  padding: 0.25em 0.75em;
  color: $primary-white;
  font-weight: bold;
  font-size: 12px;
}

.red {
  background-color: $primary-red;
}

.blue {
  background-color: $primary-blue;
}
