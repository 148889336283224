// Customize any of the theme’s variables here, for instance $btn-color: red;
/*******************
* New Brand Colors
********************/
// Text
$prim-black: #020606;
$prim-light-black: #2f3232;
$prim-gray: #5d6161;
$prim-light-gray: #8d9292;
$prim-white: #fff;

// Background
$background-dark-gray: #b0b2b2;
$background-gray: #c2c4c4;
$background-light-gray: #ebecec;
$background-lightest-gray: #f5f7f7;
$background-off-white: #fafcfc;

// Primary
$prim-lightest-blue: #dfeff8;
$prim-light-blue: #4cc9fa;
$prim-blue: #008dd1;
$prim-dark-blue: #00283c;

// Secondary
$sec-lightest-red: #ffeae0;
$sec-light-red: #f1a087;
$sec-red: #e07063;
$sec-dark-red: #861002;

// Semantic
$semantic-lightest-red: #fff3f6;
$semantic-light-red: #e47a8d;
$semantic-red: #db3553;
$semantic-dark-red: #8b0234;

$semantic-lightest-green: #edfffd;
$semantic-light-green: #74e5c3;
$semantic-green: #13aa7d;
$semantic-dark-green: #045533;

$semantic-lightest-yellow: #fffaf1;
$semantic-light-yellow: #edbf7b;
$semantic-yellow: #e89315;
$semantic-dark-yellow: #7d4d05;

// Other
$other-lightest-purple: #f4ebff;
$other-light-purple: #b480f9;
$other-purple: #6b0be7;
$other-dark-purple: #420296;

// Gradient
$blue-gradient: linear-gradient(90deg, #3a87C7 0%, #66cffa 100%);

/*********************************************************/
// Legacy Colors: Keeping for fallback cases
$primary-black: $prim-black;                    //#3b3e40;
$primary-gray: $prim-gray;                      //#7c7c7c;
$primary-light-gray: $prim-light-gray;          //#a3a2a2;
$primary-lightest-gray: $background-dark-gray;  //#b4b4b4;
$primary-light-blue: $prim-lightest-blue;       //#d0f1ff;
$primary-aqua-blue: $prim-light-blue;           //#30d1ff;
$primary-blue: $prim-blue;                      //#3886c5;
$primary-dark-blue: $prim-dark-blue;            //rgba(33, 90, 174, 1);
$primary-pink: $sec-lightest-red;               //#f8eae9;
$primary-red: $sec-red;                         //#e8615b;
$primary-white: $prim-white;                    //#f7f7f7;
$primary-off-white: $background-off-white;      //#F1F1F5;
$primary-dark-white: $background-light-gray;    //#e3e3e3;
$primary-green: $semantic-green;                //#82C43C;
$primary-yellow: $semantic-yellow;              //#e89315;

$secondary-blue: $prim-light-blue;              //#3acdfa;

$basic-white: $prim-white;                      //#ffffff;
$box-shadow-color: rgba(0, 0, 0, 0.16);

$background-gradient: linear-gradient(227deg, $primary-blue, $primary-black);
$logo-gradient: linear-gradient(45deg, $primary-blue, $secondary-blue);

$max-container-width: 60rem;
