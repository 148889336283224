.user-details {
    .MuiPaper-elevation1 {
        box-shadow: none;
    }

    .search-header-section {
        padding: 0 .5em;

        .add-new-candidate {
            text-align-last: right;
        }
    }

    .MuiIconButton-root {
        border-radius: 2px;

        &.Mui-disabled {
            p {
                opacity: 0.35;
            }
        }

        &.text-button {
            display: inline-block;
            padding: 5px 10px;
            align-self: center;
            box-sizing: border-box;
            border-radius: 2px;
            width: 48%;
            font-size: 16px;

            @media screen and (max-width: 1460px) {
                &.delete, &.edit {
                    width: 100%;
                    margin-bottom: 4px;
                }
            }

            @media screen and (max-width: 959px) {
                &.add, &.search {
                    width: 100%;
                    max-width: 100%;
                }
            }
            @media screen and (min-width: 960px) {
                &.add {
                    max-width: 140px;
                }
            }
            
            &.add {
                min-width: 140px;
                padding: 8px 10px;
                background: #008DD1;
                color: white;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                font-size: 16px;
                margin-right: 4px;

                &:hover {
                    cursor: pointer;
                    background-color: #008dd1cf;
                }
            }

            &.search {
                min-width: 105px;
                background: linear-gradient(90deg, #3A87C7 0%, #66CFFA 100%);
                padding: 8px 10px;
                border-radius: 8em;
                color: white;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                font-size: 16px;
                margin-right: 4px;

                &:hover {
                    cursor: pointer;
                    background-color: #008dd1cf;
                }
            }
        
            &.delete {
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                color: #E07063;
                border: 1px solid #E07063;
            }
        
            &.edit {
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                color: #008DD1;
                border: 1px solid #008DD1;
                margin-right: 4px;
            }
        
            &:hover {
                cursor: pointer;
                background-color: rgba(0, 0, 0, .04);
            }
        }  
    }
    .user-details-card {
        .detail-card-container {
            display: inline-flex;
            padding: 1em;
            border-bottom: 1px solid #F5F7F7;

            .detail-card-header {
                display: inline-flex;
            }
        }

        .user-details {
            display: inline-flex;
            padding: 1em;
            border-bottom: 1px solid #F5F7F7;

            @media screen and (max-width: 959px) {
                display: contents;
            }

            &:last-child {
                border-bottom: none;
            }
            
            .detail-card-content {
                display: table-cell;
                align-self: center;

                @media screen and (max-width: 959px) {
                    display: block;
                }

                &.email {
                     p {
                        white-space: nowrap; 
                        width: 85%; 
                        overflow: hidden;
                        text-overflow: ellipsis; 
                     }
                }
            }
        }
    }
}