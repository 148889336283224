@import "../../../styles/variables";

.confirm-container-main {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;
  padding: 0;

  .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    font-size: 18px;
  }

  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 20px) scale(1) !important;
  }

  .MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
    transform-origin: top left;
  }


  .MuiOutlinedInput-inputMarginDense {
    padding-top: 18.5px;
    padding-bottom: 17.5px;
  }

  h4 {
    width: 100%;
    padding-left: 1em;
  }

  p {
    padding-bottom: 0.5em;
  }

  .input-field-container {
    padding: 0 2em !important;
  }

  @media only screen
    and (min-width: 768px)
    and (max-height: 1366px)
    and (orientation: portrait)
    and (-webkit-min-device-pixel-ratio: 1.5) {
      height: 100%;
  }

  .color-button {
    padding: 0.75rem 5rem
  }

  .confirm-form-container {
    height: 100%;
    width: 100%;

    .MuiOutlinedInput-root {
      position: relative;
      border-radius: 2px;
    }

    .MuiBox-root-6 {
      width: 83%;
    }

    h1 {
      width: 83%;
      text-align: left !important;
    }

    .confirm-form {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 40rem;
      width: 100%;
      margin: 0 auto;
      padding: 0 1em;

      h1 {
        color: $primary-black;
        text-align: center;
      }

      h4 {
        padding-bottom: 0.25em;
      }

      .confirm-button {
        width: 242px;
        height: 46.94px;
        background: $primary-blue;
        border-radius: 10px;
        color: #F7F7F7;
        font-weight: 700;
        border: none;
        font-size: 14px;
        line-height: 14px;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .confirm-form-container {
      align-items: center;
      background-color: $primary-blue;

      .confirm-form {
        background-color: white;
        padding: 20px 0;
        margin-top: -2em;
        width: 70%;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .confirm-form-container {
      .confirm-form {
        width: 60%;
      }
    }
  }

  @media screen and (min-width: 1235px) {
    height: 100%;

    .confirm-form-container {
      .confirm-form {
        width: 40%;
      }
    }
  }

  .change-contact {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
  }
}

.update-phone-form {
  .update-phone-input-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .update-phone-button-container {
    text-align: center;

    .update-phone-button {
      &:disabled {
        background-color: #8D9292;
        cursor: not-allowed;
      }
    }

    .color-button {
      padding: 0.25rem 4rem;
      border-radius: 4em;
      font-weight: 200;
      background-image: none;
      background-color: #3886c5;
    }
  }
}
