@import "../../../styles/variables";
.nav {
  display: flex;
  padding: 1em 1em 0.5em 1em;
  z-index: 4;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4rem;
  background-color: $basic-white;

  .contact-recruiter {
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      text-decoration: underline;
      color: $primary-blue;
      margin-right: 0.5em;
      font-weight: bold;
      text-underline-position: under;
    }

    &:hover {
      p {
        text-decoration: none;
        color: $primary-dark-blue;
      }
    }
  }

  .back-icon {
    width: fit-content;
    &:hover {
      cursor: pointer;
    }
  }
}
