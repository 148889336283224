@import "../../../../styles/variables";

.job-container {
  overflow-x: auto;

  @media screen and (max-width: 767px) {
    padding: .5em;
  }

  ul.react-multi-carousel-track {
    margin: 0
  }
}

.matched-jobs-skeleton {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  height: fit-content;
  justify-content: flex-start;
  gap: 1em;
  padding: 1em .5em;
  flex-wrap: nowrap;

  .matched-card {
    border-radius: 15px;
    width: 49%;

    &:nth-of-type(2n) {
      display: none;
    }

    @media screen and (min-width: 768px) {
      width: 32%;

      &:first-of-type {
        display: none;
      }

      &:nth-of-type(2n) {
        display: block;
      }
    }

    @media screen and (min-width: 960px) {
      width: 24%;

      &:first-of-type {
        display: block;
      }
    }
  }
}

.matched-jobs {
  padding: 1em .5em;

  .job-card {
    height: 100%;
    margin-bottom: 1em;

    .job-duration,
    .job-start {
      display: none;
    }
  }

  .react-multiple-carousel__arrow {
    background: #DFEFF8;

    &--right,
    &--left {
      &:before {
        display: none;
      }
    }

    &:hover {
      background: darken(#DFEFF8, 10%);
    }
  }

  .react-multi-carousel-item {
    opacity: .5;
    padding: 0 .5em;

    &--active {
      opacity: 1;
    }
  }

  @media screen and (min-width: 1280px) {
    flex-wrap: nowrap;
  }

  &.explore-area {
    .matched-card {
      width: 100%;
    }
  }

  .loading {
    margin: 0 auto;
  }

  a {
    height: 100%;
  }
}
