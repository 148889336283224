@import "../../../../styles/variables";

.signup-container-main {
  &.recruiter {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    position: relative;
    padding: 0;
    font-family: "ubuntu";

    .code-input {
        .resend-code {
            margin: 1em 0;

            .resend {
                display: contents;
            }

            p {
                width: 100%;
            }

            .color-button {
                font-size: 16px;
                padding: 1px 10px;
                width: 100%;
                margin-left: 0.5em;
            }
        }

        .input-field {
            width: 100%;
        }

        .input-field-container {
            &.phone, &.email {
                width: 100%;
            }
        }
    }

    .color-button {
        &:disabled {
            pointer-events: none;
            &:hover {
                cursor: not-allowed;
            }
        }
    }

    .detail-contact {
        width: 100%;

        &.email {
            margin-bottom: 1em;
        }

        .phone-wrapper {
            border-top: 1px solid #8d9292;
            padding-top: 1em;
        }

        .phone-wrapper, .email-wrapper {
            button {
                width: 150px;
            }
        }
    }

    .confirm-success {
        display: inline-flex;

        .MuiSvgIcon-root {
            fill: $primary-blue;
        }

        .msg-text {
            padding-left: 1em;
        }
    }

    .recruiter-avatar {
        margin-bottom: 1.25em;
    }
  
    @media only screen
      and (min-width: 768px)
      and (max-height: 1366px)
      and (orientation: portrait)
      and (-webkit-min-device-pixel-ratio: 1.5) {
        height: auto;
    }
  
    main {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 3.25rem;
      height: calc(100% - 3.25rem);
      }
  
    .padding-0-2p {
      padding: 0 2%;
    }
  
    .padding-0-5p {
      padding: 0 5%;
    }
  
    .padding-t-5p {
      padding-top: 5%;
    }
  
    .txt-center {
      text-align: center;
    }
  
    .MuiCircularProgress-root.dataLoading {
      color: $primary-blue;
    }
  
    .color-button {
      border-radius: 32px;
      font-size: 18px;
      padding: 8px;
      width: 100%;
  
      &:hover {
        @media only screen and (min-width: 1025px) {
          box-shadow: 0 3px 6px $box-shadow-color;
          filter: brightness(1.1);
          transition: all 0.2s ease-in-out;
        }
      }
    }
  
    .previous-step {
      background: #fff;
      border: 1px solid #8d9292;
      text-shadow: none;
      color: #8d9292;
      transition: all .5s ease;
  
      &:hover {
        background: #8d9292;
        color: #fff;
      }
    }
  
    .signup-form-container {
      width: 100%;

      &.account {
        height: 165vh;
      }

      &.verify {
        height: 100vh;
      }

      .input-icon {
        margin-bottom: 20px;
      }
  
      .MuiOutlinedInput-notchedOutline {
        border-color: #5D6161;
        border-radius: 2px;
      }
  
      legend.MuiFormLabel-root {
        color: #020606;
        font-size: 18px;
        margin-bottom: 8px;
      }
  
      h1 {
        color: #020606;
        font-size: 28px;
        margin-bottom: 16px;
      }
    
      p:not(.MuiFormHelperText-root.Mui-error) {
        color: #5d6161;
        padding-bottom: 16px;
        font-size: 16px;
  
        &.MuiFormHelperText-contained {
          font-size: 14px;
          margin: 0;
        }
      }
  
      .sign-up-with {
        position: relative;
        font-size: 14px;
        font-weight: normal;
        text-align: center;
        color: #B0B0B0;
        margin: 4% 1% 0 1%;
  
        &::before,
        &::after {
          position: absolute;
          content: "";
          top: 50%;
          border-top: 1px solid #E7E9EE;
          width: 35%;
          transform: translateY(-50%);
        }
  
        &::before {
          left: 2.5%;
        }
        &::after {
          right: 2.5%;
        }
      }
  
      .location-grid {
        position: relative;
  
        svg {
          cursor: pointer;
          position: absolute;
          right: 10px;
          top: 45px;
        }
  
        .MuiAutocomplete-endAdornment {
          display: none;
        }
      }
    }
  
    @media screen and (min-width: 768px) {
      .signup-form-container {
        align-items: center;
        background-color: $primary-blue;
  
        .signup-form {
          background-color: white;
          border-radius: 18px;
          padding: 30px 0;
          margin-top: -2em;
          width: 70%;
        }
      }
    }
  
    @media screen and (min-width: 1025px) {
      flex-direction: row;
  
      .signup-form-container {
        align-items: center;
        background-color: $primary-blue;
  
        .signup-form {
          width: 60%;
          background-color: white;
          border-radius: 18px;
          margin-top: -2em;
        }
      }
    }
  
    @media screen and (min-width: 1350px) {
      height: auto;
  
      .signup-form-container {
        .signup-form {
          width: 80%;
        }
      }
    }

    .specialty-select {
        [class*="control"] {
          width: 100%;
          padding: 5px;
          overflow-y: scroll;
          height: 48px;
    
          [class*="IndicatorsContainer"] {
            width: 80px;
            justify-content: right;
            height: 35px;
            align-self: baseline;
          }
    
          [class*="indicatorContainer"] {
            position: fixed;
            width: 35px;
            margin-right: 35px;
    
            &:last-of-type {
              margin-right: 0;
            }
          }
    
          [class*="indicatorSeparator"] {
            height: 20px;
            position: fixed;
            margin-right: 35px;
            width: 1px;
          }
    
          [class*="multiValue"] {
            border-radius: 5em;
            padding: .3em;
    
            :nth-child(2) {
              border-radius: 8em;
            }
          }
        }
    
        [class*="menu"] {
          z-index: 100;
        }
    }   
  }
}

.required {
  font-size: 14px;
  
   &.alert {
    color: red;
   }
}