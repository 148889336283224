@import "../../../styles/variables";

.login-container-main {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;

  .login-message {
    text-align: center;
  }

  @media only screen
    and (min-width: 768px)
    and (max-height: 1366px)
    and (orientation: portrait)
    and (-webkit-min-device-pixel-ratio: 1.5) {
      height: 100%;
  }

  .info-carousel-container .info-carousel-content h3 {
    font-size: 21px;
  }

  .login-form-container {
    height: fit-content;
    min-height: 100vh;
    width: 100%;

    .logo-box {
      .logo {
        position: relative;
        @media screen and (max-width: 767px) {
          width: 64px;
        }
      }
    }

    .color-button {
      width: 100%;
    }

    .show-password {
      color: $primary-gray;
      cursor: pointer;
      text-transform: uppercase;
    }

    .text-link {
      color: $primary-blue;
      font-size: 0.875rem;
    }

    .sign-up-with {
      position: relative;
      font-size: 1rem;
      text-align: center;
      margin: 10% 1% 0 1%;

      &::before,
      &::after {
        position: absolute;
        content: "";
        top: 50%;
        border-top: 1px solid #d5d5d5;
        width: 20%;
        transform: translateY(-50%);
      }

      &::before {
        left: 2.5%;
      }
      &::after {
        right: 2.5%;
      }
    }

    .color-button {
      font-size: 18px;
    }

    .social-media-login {
      span {
        opacity: 1 !important;
      }

      .social-login-button {
        background-color: $basic-white;
        border: none;
        box-shadow: 0 3px 6px $box-shadow-color;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4.125rem;
        height: 3.8125rem;
        border-radius: 19px;
      }
    }

    .sign-up {
      text-align: center;

      span {
        margin-left: 0.5em;
      }
    }
  }

  .login-info-container {
    display: none;
  }

  @media screen and (min-width: 768px) {
    .login-form-container {
      align-items: center;
      background-color: $primary-blue;

      .login-form {
        background-color: white;
        border-radius: 18px;
        padding: 20px 45px;
        margin-top: -2em;
        width: 60%;

        h1 {
          text-align: center;
        }

        .sign-up-with {
          position: relative;
          font-size: 1rem;
          text-align: center;

          &::before,
          &::after {
            width: 25%;
          }

          &::before {
            left: 2.5%;
          }
          &::after {
            right: 2.5%;
          }
        }
      }

      .link-button {
        padding-right: 5%;
      }
    }
  }

  @media screen and (min-width: 1025px) {
    flex-direction: row;

    .login-form-container {
      width: 50%;
      align-items: center;
      background-color: $primary-blue;

      .login-form {
        width: 75%;
        background-color: white;
        border-radius: 18px;
        padding: 20px 45px;
        margin-top: -2em;

        h1 {
          text-align: center;
        }
      }

      &.admin {
        width: 100%;

        .login-form {
          width: 50%;
        }
      }

      .sign-up-with {
        &::before,
        &::after {
          width: 20%;
        }

        &::before {
          left: 2.5%;
        }
        &::after {
          right: 2.5%;
        }
      }
    }

    .login-info-container {
      align-items: center;
      display: block;
      width: 50%;
      padding: 10px;
    }
  }

  @media screen and (min-width: 1350px) {
    height: 100%;

    .login-form-container {
      .login-form {
        width: 55%;
      }

      .sign-up-with {
        &::before,
        &::after {
          width: 25%;
        }

        &::before {
          left: 2.5%;
        }
        &::after {
          right: 2.5%;
        }
      }
    }
  }
}
