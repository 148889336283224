@import "../../../styles/variables";


.main {
  height: calc(100% - 4.25rem);
}

.support-container-main {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;

  @media only screen
    and (min-width: 768px)
    and (max-height: 1366px)
    and (orientation: portrait)
    and (-webkit-min-device-pixel-ratio: 1.5) {
      height: 100%;
  }

  .support-form-container {
    width: 100%;
    padding-top: 25px;
    align-items: center;
    margin-bottom: 30px;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 32px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #3B3E40;
      margin-bottom: 10px;
    }

    .sub-title {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #3B3E40;
    }

    .support-form {
      width: 100%;
      margin-top: 60px;

      .title {
        text-align: left;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 22px;
        letter-spacing: -0.02em;
        color: #7C7C7C;
        padding-left: 5%;
      }

      .form-input-container {
        background: white;
        border-radius: 18px;
        padding: 25px 35px;
        margin-top: 15px;
        & > * + * {
          margin-top: 30px;
        }
      }

      .description-title {
        margin-top: 31px;
        color: #3B3E40;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
      }

      .send-button-container {
        text-align: right;
        margin-top: 22px;

        .send-button {
          width: 150px;
          height: 46.94px;
          background: $primary-blue;
          border-radius: 10px;
          color: #F7F7F7;
          font-weight: 700;
          font-size: 14px;
          border: none;
          font-size: 14px;
          line-height: 14px;
          align-items: center;
          text-align: center;
          letter-spacing: -0.02em;
        }
      }
    }
  }

  .tips.support-info {
    background: #f7f7f7;
    border-radius: 18px;
    border: 1px solid #e9e9e9;
    padding: 15px;
  }

  .support-info-container {
    background: #ffffff;
    padding: 25px;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    @media only screen and (min-width: 1025px) {
      height: calc(100% - 69px);
    }



    .support-info {
      width: 100%;
    }

    .tips {
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #3B3E40;
        margin-bottom: 10px;
      }

      .description {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #7C7C7C;
      }
    }

    .support-image {
      width: 100%;
      height: auto;
      max-width: 300px;
    }

    .support-team-title {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
      align-items: center;
      letter-spacing: -0.02em;
      color: #7C7C7C;
      margin-bottom: 20px;
    }

    .support-team-contact {
      display: flex;
      flex-direction: row;
      color: $primary-blue;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      margin-top: 15px;
      margin-bottom: 15px;

      .support-icon {
        font-size: 24px;
        margin-right: 10px;
      }
    }
  }

  @media screen and (min-width: 768px) {
    padding: 25px;

    .support-form-container {
      margin-bottom: 50px;

      .support-form {
        margin-left: 10%;
        width: 80%;
      }
    }

    .support-info-container {
      border-radius: 18px 18px 0 0;
      flex-direction: row-reverse;

      .support-info {
        width: 33%;
      }
    }
  }

  @media screen and (min-width: 1025px) {
    padding: 0;
    flex-direction: row;

    .support-form-container {
      width: 75%;
      align-items: center;
      margin-bottom: 0px;

      .support-form {
        margin-left: 15%;
        width: 70%;
      }
    }

    .support-info-container {
      border-radius: 0;
      flex-direction: column;
      width: 25%;
      padding: 50px 3%;
      margin-top: -100px;

      .support-info {
        width: 100%;
        word-break: break-word;
      }
    }
  }

  @media screen and (min-width: 1350px) {
    .support-form-container {
      .support-form {
        margin-left: 20%;
        width: 55%;
      }
    }
  }
}

.close-btn {
  top: 15px;
}
