@import "../../../styles/variables";

.modal-content {
  margin-bottom: 0;
  
  &.confirmation {
    align-items: start;
    background-color: #fff;
    padding: 1.5em;
    text-align: center;
    width: 100%;
  
    .message {
      width: 100%;
  
      h3, p {
        color: $primary-black;
      }
  
      p {
        color: #020606;
        margin: 1em 0;
        font-size: 1rem;
      }
    }
  
    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
}
