@import "../../../styles/variables";

.container {
  p {
    color: #5d6161;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    & > div + div {
      border-top: 1px solid#ccc;
      padding-top: 30px;
    }
    p {
      text-align: center;
    }
  }

}
