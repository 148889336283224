@import "../../../../styles/variables";

.specialty-section {
  padding: 1.5em 0;
  position: relative;
}

.primary {
  margin-bottom: 2em;
  padding-bottom: 2em;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -4em;
    right: -4em;
    height: 2px;
    background: $primary-white;
    box-shadow: 2px 2px 4px $box-shadow-color;
  }
}

.specialty-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding-bottom: 1em;

  ul {
    margin-left: 2em;
  }

  .detail {
    margin-bottom: 1em;
  }
}


.specialty-edit {
  .primary:after {
    background: none;
    box-shadow: none;
  }

  .MuiFormGroup-row {
    flex-direction: column;
  }
}

.speciality-select-item.Mui-selected, .speciality-select-item:hover {
  background-color: $primary-blue !important;
}