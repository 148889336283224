@import "../../../../styles/variables";

.main {
  height: calc(100% - 4.25rem);
}

.header-container {
  width: 100%;
  padding-left: 46px;
  padding-top: 28px;

  .header-text {
    margin-left: 21px;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    text-transform: none;
  }
}

.skillset-container-main {
  box-sizing: border-box;
  width: 100%;
  padding: 25px 1.5em 1.5em 1.5em;

  .card {
    background-color: white;
    padding: 16px;
    box-shadow: 0px 2px 4px rgba(2, 6, 6, 0.08);
    border-radius: 8px;
  }

  .completion-date {
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #B0B2B2;
  }

  .skillset-form-container {
    align-items: center;

    .skill-name {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 32px;
      text-transform: none;
    }

    .size-instructions {
      margin-top: 16px;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;

      svg {
        margin-right: 8px;
      }
    }

    .score-instructions {
      .item {
        margin-top: 16px;
        margin-bottom: 16px;
      }

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }

      .description {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
      }

      .level {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #5D6161;
      }
    }

    .start-button, .submit-button {
      padding: 8px 16px;
      width: 120px;
      height: 40px;
      background: #008DD1;
      border-radius: 32px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      color: white;
      text-transform: none;

      &:hover {
        cursor: pointer;
      }

      &:disabled {
        background-color: #8D9292;
        cursor: not-allowed;
      }
    }

    .save-progress-button {
      width: 160px;
      margin-right: 10px;

      padding: 8px 16px;
      height: 40px;
      background: #ffffff;
      border-radius: 32px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      color: #008DD1;
      text-transform: none;

      &:hover {
        cursor: pointer;
      }

      &:disabled {
        background-color: #8D9292;
        cursor: not-allowed;
      }

      &.saving-progress {
        background-color: #8d92929e;

        span {
          grid-gap: 10px;
        }
      }

      .spinner {
        color: $primary-blue;
      }
    }

    .section {
      .progress-card-grid-container {
        margin-top: 20px;
        margin-bottom: 16px;
      }

      .name-container {
        margin-bottom: 8px;

        .name {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0em;
          color: #008DD1;
          text-transform: none;
        }
      }

      .experience-level-container {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #5D6161;

        .experience-level {
          text-align: center;
        }
      }

      .question-list-container {
        min-height: 50vh;
        max-height: 50vh;
        padding-top: 5px;
        padding-bottom: 5px;
        overflow-y: overlay;
        overflow-x: hidden;

        .question-container {
          padding-top: 12px;
          padding-bottom: 12px;

          .name {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            color: #020606;
          }

          .score {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .experience-level {
              font-size: 12px;
            }

            &:hover {
              cursor: pointer;
            }

            .selected {
              height: 32px;
              width: 32px;
              background: #008DD1;
              border: 2px solid #008DD1;
              border-radius: 50%;
            }

            .unselected {
              height: 32px;
              width: 32px;
              border: 2px solid #020606;
              border-radius: 50%;
            }
          }
        }
      }

      .pagination-container {
        padding-top: 20px;

        .next {
          text-align: right;

          @media screen and (max-width: 1359px) {
            .submit-button {
              margin-bottom: 5px;
            }
          }
        }

        .previous {
          text-align: left;
        }

        .paginate-button {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;

          padding: 8px 16px;
          height: 40px;
          background: #008DD1;
          border-radius: 32px;
          color: white;
          text-transform: none;

          svg {
            path {
              stroke: white;
            }
          }


          &:hover {
            cursor: pointer;
          }

          &:disabled {
            color: #8D9292;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  .skillset-list-container {
    padding-bottom: 1.5em;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 20px;
    }

    .skill-list {
      margin-top: 24px;

      .skill {
        margin-bottom: 27px;

        .name {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          margin-bottom: 11px;
        }

        .action-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          button {
            min-width: auto;
            padding: 4px 4px;
          }
        }
      }
    }
  }

  .progress-card-grid-container {
    display: inline-flex;
    align-items: center;

    &.current {
      .percentage-label {
        padding-left: 16px;
      }
    }
  }

  .progress-bar {
      margin-right: .5em;
  }

  .progress {
      color: #969696;
      background-color: transparent;
      padding: 0;
  }

  .progress-header {
      color: #3b3e40;
      margin-bottom: 1em;
      font-size: 18px;
  }

  .progress-card-grid-container {
      .MuiCheckbox-colorSecondary.Mui-disabled {
          color: #6dd19d;
      }

      .filler {
        background: #E89315;
    }
  }

  .percentage-label {
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #B0B2B2;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;

    .skillset-form-container {
      width: 65%;
      padding-right: 0;
    }

    .skillset-list-container {
      padding-right: 0;
    }
  }

  @media screen and (min-width: 960px) {
    .skillset-form-container {
      padding-right: 18px;
    }
  }
}
