@import "../../../styles/variables";

.progress-card-container {
    padding: 1.5em;
    border: 1px solid #e6e6ec;
    border-radius: 1em !important;
    box-shadow: none !important;
    width: 100%;

    .hide-complete-button {
        display: none;
    }

    h3 {
        color: #3b3e40 !important;
    }

    &.documents {
        h3 {
            margin-bottom: 0.75em;
        }
    }

    .checkbox-wrapper-incomplete {
        width: 100%;
        cursor: pointer;

        .checkbox-incomplete {
            display: block;
            width: 100%;

            label {
                margin-right: 0;
            }

            .arrow {
                align-self: center;
                float: right;
                padding-top: 10px;

                svg {
                    color: #a3a2a2;
                }
            }
        }
    }

    .personal-details {
        display: grid;
        align-content: center;
        grid-template-columns: 0.5fr minmax(0, 5fr);
        min-width: 0;
        min-height: 0;
        padding-left: 0;

        .email {
            color: #969696;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 90%;
        }
    }

    .profile-details {
        display: inline-block;
    }

    .progress-card-grid-contaner {
        display: inline-flex;
        align-items: center;
        max-width: 100%;
    }

    .progress-bar {
        margin-right: .5em;
    }

    .progress {
        color: #969696;
        background-color: transparent;
        padding: 0;
    }

    .progress-header {
        color: #3b3e40;
        margin-bottom: 1em;
        font-size: 18px;
    }

    .progress-card-professional-grid-contaner {
        .MuiCheckbox-colorSecondary.Mui-disabled {
            color: #6dd19d;
        }
    }

    .color-button {
        padding: .5em 5px;
        width: 100%;

        @media screen and (min-width: 1280px) and (max-width: 1480px) {
            font-size: 14px;
        }
    }
}
