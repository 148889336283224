@import "../../styles/variables";

.information-wrapper {
    width: 100%;
    max-width: $max-container-width;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.desktop-view {
      @media only screen and (max-width: 1279px) and (min-width: 960px) {
        .job-preferences, .summary-details {
          .card-wrapper {
            min-height: 235px;
          }
        }
      }

      @media screen and (min-width: 1279px) {
        padding-left: 1em;
      }

      @media screen and (max-width: 959px) and (min-width: 768px) {
        padding-right: 1.5em;
        padding-left: 1.5em;
      }

      .tabs-container {
        margin-bottom: 1em;
        margin-left: .15em;

        .MuiTab-root {
          text-transform: inherit;
          padding: 0;

          .MuiTab-wrapper {
            font-size: 18px;
            padding: 16px;
          }
        }

        .MuiTabs-indicator {
          background-color: $primary-blue;
        }

        .Mui-selected {
          .MuiTab-wrapper {
            color: $primary-blue;
          }
        }
      }

      h3 {
        color: #020606;
      }

      .tablet-mobile-view {
        .checkbox-container {
          @media screen and (max-width: 768px) {
            .progress-card-container {
              border-radius: 0em !important;
            }
          }

          background: transparent;
          padding: .5em 0em;
        }
    
        .detail-checkbox-container {
          margin-top: 1em;
        }

        .progress-card-container {
          background-color: white !important;
        }

        .progress-card-container.documents {
          margin-top: 0;
        }
      }

      .personal-wrapper .profile-details .detail .phone-wrapper .code-input, .personal-wrapper .profile-details .detail .email-wrapper .code-input {
        margin-left: 10px;
        font-size: 8px !important;
        margin-top: 0px;
        background: #ffffff80;
        border-radius: 8px;
        padding-left: 1em;
      }
      
      width: 100%;
      max-width: inherit;
      margin: 0;

      @media only screen and (min-width: 767px) and (max-width: 1278px) {
        .main-container {
          padding: 1em 0 1em 0;
          margin: .5em 0em;
        }
      }

      @media only screen and (min-width: 1279px) {
        .main-container {
          padding: 1em 1em 1em 0;
          margin: .5em 0em;
        }
      }

      .checkbox-container {
        padding: 1.5em 1em 2.75em 0em;
      }

      .detail-checkbox-container {
        margin-top: 2em;
      }

      .add-container {
        .add-wrapper {
          max-width: 100%;
          margin: 0 auto;
          margin-right: 0;
          box-shadow: none;
        }
      }

      .personal-fields {
        .card-wrapper {
          padding-right: 16px;
          margin-bottom: 0px !important;
        }
        
        .add-tile-button {
          margin-bottom: 0;

          h3 {
            font-weight: 600;
          }
        }
      }

      .profile-fields {

        div:not(.personal-details-container) .card-wrapper { 
          @media only screen and (max-width: 767px) {
            box-shadow: none;
            border-radius: 0;
          }

          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
          background-color: white;
          padding: 25px;
          padding: 0.5em 1em;
          margin-bottom: 1em;
          margin-right: 0;
          max-width: 100%;
          border-radius: 8px;

          .subheader {
            h3 {
              color: $primary-black;
            }
          }
  
          .specialty-card .detail {
              margin-bottom: .5em;
          }
        }
        @media only screen and (max-width: 767px) {
          div.personal-details-container {
            border-top-left-radius: 0px !important;
            border-top-right-radius: 0px !important;
            border-bottom: 0 !important;
          }
        }
  
        div.personal-details-container {
            border-radius: 5px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            margin-bottom: 1em;
            background-color: white;
            padding-bottom: 1em;
  
          > .card-wrapper {
            max-width: 100%;

            .main-container {
              padding-left: 2em !important;
            }
  
            .subheader {
              width: auto;
              align-items: flex-start;
              justify-content: flex-end;
  
              h3 {
                display: none;
              }
  
              .icon-button {
                margin-right: 1em;
  
                svg {
                  color: white;
                }
  
              }
  
              .alert-danger {
                background: #e8615b82;
                padding: .3em;
                border: 1px solid white;
                border-radius: 8px;
                margin-left: 1em;
                color: white;
              }
  
              .edit-button {
                  margin-left: 1em;
              }
            }
        
            .personal-wrapper {
              display:flex;
  
              .MuiAvatar-root.MuiAvatar-circle {
                margin: 0 0 0 1.3em;
  
                img {
                  border: 7px solid white;
                  border-radius: 100%;
                }
              }
  
              .profile-details {
  
                .detail-contact {
                  
                  .verify-contact {
                    color: #3886c5;
                  }
  
                  svg {
                    color: white;
                  }
                }
  
                .name {

                  h1 {
                    font-size: 28px;
                    color: #020606;
                  }
                }
              }
            }
          }
        }
      }
      
      .summary-details {
        .card-wrapper {
          padding-left: 0 !important;
          padding-right: 0 !important;
          padding-bottom: 0 !important;
        }

        .subheader {
          border-bottom: 1px solid #e7e7e7;
          padding-bottom: .5em;
          padding: 0 1em 1em;
        }

        .main-content {
          .summary-wrapper {
            padding: 1em;
          }

          .footer-data {
            border-top: 1px solid #e7e7e7;
            padding: 1em;
  
            .footer-item {
              padding-bottom: .5em;

              p {
                color: #969696;
              }
            }
          }
        }
      }

      .details {
        padding-bottom: .5em;

        .shift-details, .job-detail, .footer-item {
          display: flex;
          align-items: center;
          padding-bottom: .5em;

          svg {
            height: 1rem !important;
            width: 1rem !important;
            color: #969696;
          }

          p {
            display: inline;
            vertical-align: middle;
            color: #969696;

            &:nth-child(2) {
              padding-left: 1.5em;
            }
          }
        }
      }

      div[data-testid="education-details"] {
        .details :not(.school) {
          color: #969696;

          &:nth-child(2), &:nth-child(3), &:nth-child(4) {
            display: inline-flex;
          }
        }

        .content-tag {
          padding: 0 4px;

            &:before {
              content:" • ";
          }
        }
      }
    }
  
    .instructions {
      color: $primary-blue;
      font-weight: bold;
      font-size: 18px;

      @media only screen and (max-width: 600px) {
        font-size: 12px;
      }
    }
  
    h3 {
      color: $primary-light-gray;
    }
  
    .category {
      width: 100%;
      text-align: right;
    }
  
    .add-container {
      width: 100%;
      padding: 2em 0;
    }
  
    .add-wrapper {
      margin: 0.5em 0;
    }
  }
  
  .back {
    width: 100%;
    display: flex;
    justify-content: flex-start;

  }
  
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2em;
    }


.details {
  span{
    margin-left: .25em;
  }
}


