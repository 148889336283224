@import "../../../styles/variables";

.card-wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 40rem;
  padding: 1em 0;

  .main-card-view {
    width: 100%;

    h2 {
      font-style: normal;
      font-size: 28px;
      line-height: 32px;
    }

    .main-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }

    .subheader {
      border-bottom: 1px solid #EBECEC;
      margin-bottom: 0.5em;
      padding-bottom: 0.5em;

      h2 {
        color: #020606;
        font-size: 28px;
      }

      .header-icon {
        @media screen and (min-width: 768px) {
          margin-right: .5em;
        }
      }


    }
  }

  .main-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .subheader {
    &.aligned {
      align-items: baseline;
    }

  }
}

.personal-wrapper {
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}
.information-wrapper.desktop-view .profile-fields div.personal-details-container > .card-wrapper .personal-wrapper .profile-details {
    @media screen and (max-width: 767px) {
      margin-left: 15px;
    }
  }

.information-wrapper.desktop-view .profile-fields div.personal-details-container > .card-wrapper .subheader p.alert-danger + .icon-button {
  @media screen and (max-width: 767px) {
    margin-top: 15px;
  }
}
.information-wrapper.desktop-view .profile-fields div.personal-details-container > .card-wrapper .subheader p.alert-danger {
  @media screen and (max-width: 767px) {
    background: #e8615b;
    font-size: 10px;
    position: absolute;
    margin-top: -11px;
    margin-right: -2px;
    padding: 0 10px;
    text-transform: uppercase;
    border-radius: 0 8px 0 8px;
  }
}

.medical-certification-container {
  h4 {
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }

  .buttons {
    align-self: center;
  }

  .text-button {
    display: inline-block;
    padding: 5px 10px;
    align-self: center;

    &.delete {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #E07063;
    }

    &.edit {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #008DD1;
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, .04);
      border-radius: 5px;
    }
  }

  .header-icon {
    display: none !important;
  }

  .card-wrapper .main-card-view .subheader {
      border-bottom: none !important;
   }

  .card-wrapper .main-card-view .details-card {
    &.medical-certifications {
      @media screen and (min-width: 600px) {
        height: 43px;
      }
      @media screen and (max-width: 599px){
        .buttons {
          text-align: right;
          text-align-last: justify;
          display: inline-block;

          .delete {
            padding-left: 0;
          }
        }
        border-bottom: 1px solid #EBECEC !important;
      }

      .not-uploaded {
        font-size: 12px;
        font-style: italic;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #DB3553;
      }

      .certificate-data {
        p {
          font-size: 12px;
          font-style: italic;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: left;
          color: #B0B2B2;
        }
      }

      p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #020606;
      }
    }
  }
}

.card-wrapper .main-card-view .details-card {
  &:not(:last-child) {
    border-bottom: 1px solid #EBECEC;

    &.medical-certifications {
      border-bottom: none;
    }
  }

  h3 {
    line-height: 20px;
    margin-bottom: 0.25em;
    color: $primary-black;
  }


  .main-content {
    &.buttons {
      text-align: right;
      text-align-last: right;
      display: inline-block;
    }
  }

  .main-content {
    &.buttons {
      &.medical-cert {
        padding: 0 0 0.25em 0;

        .save-button {
          display: inline;
          padding: 0.2em 1rem;
          border-radius: 3em;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          text-transform: none;
          height: 40px;


          &:focus {
            border: initial;
            outline: initial;
          }
        }
      }

      .icon-button {
        display: inline-block;
        padding: 5px 10px;

        &.edit {
          margin-right: 0.5em;
        }

        &:last-of-type {
          margin-right: 0;
        }

        &:hover {
          cursor: pointer;
          background-color: rgba(0, 0, 0, .04);
          border-radius: 5px;
        }

        @media screen and (max-width: 767px) {
          padding: 0;
        }
      }
    }
  }

  @media only screen and (min-width: 1279px) {
    .main-content {
      &.buttons {
        .icon-button {
          display: none;
          transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
      }
    }

    &:hover {
      .main-content {
        &.buttons {
          .icon-button {
            display: inline-block;
          }
        }
      }
    }
  }
}
