@import "../../styles/variables";

.contact {
  justify-content: center;
  align-items: center;
  background-color: $primary-white;

  h1 {
    width: 100%;
    font-size: 1rem;
  }

  h1,
  p {
    text-align: center;
    color: $primary-black;
  }

  p {
    font-size: 1rem;
  }

  .browser-contact-options {
    margin-top: 2em;
    max-width: $max-container-width;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
  
    .browser-option{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1em;
      width: 100%;

      .icon {
        margin-top: .75em;
      }

      h3{
        color: $primary-dark-blue;
      }
    }

    .schedule{
      margin-top: -1em;
    }
  }

  .contact-options {
    margin-top: 3em;
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 1em;
    width: 100%;
    align-content: center;
    justify-content: center;

    .option {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
      width: 8rem;
      height: 8rem;
      background-color: $basic-white;
      box-shadow: 0px 3px 6px $box-shadow-color;
      border-radius: 19px;

      &:hover {
        background-color: $primary-light-blue;
      }

      p {
        text-decoration: underline;
        color: $primary-blue;
      }
    }
  }

  .selected-job {
    padding: 0 1em;
    margin-top: 4em;
    width: 100%;

    .job-header {
      text-transform: uppercase;
      color: $primary-light-gray;
      font-size: 0.625rem;
      text-align: left;
    }
    .job-title {
      text-align: left;
      font-size: 0.875rem;
      margin-top: 0.25em;
    }
  }
}
