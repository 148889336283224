@import "../../../styles/variables";

.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4.25rem;
  padding: 1.5em;
  box-shadow: 0 3px 6px $box-shadow-color;
  z-index: 4;
}

.filter-container {
  padding: 0 2em;
  margin: 0 auto 6em auto;
  max-width: $max-container-width;

  .condensed-pill-section {
    max-width: 100%;

    .close {
      -webkit-box-align: center;
      align-items: center;
      display: flex;
    }

    svg {
      width: 0.55em;
      height: 0.65em;
      display: inline-block;
      fill: $primary-light-gray;
      stroke: $primary-light-gray;
      stroke-width: 1;
      -webkit-box-align: center;
      align-items: center;
      cursor: default;
      display: flex;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      justify-content: space-between;
    }
  }

  .specialty-select {
    [class*="control"] {
      width: 100%;
      padding: 5px;
      overflow-y: scroll;
      height: 48px;

      [class*="IndicatorsContainer"] {
        width: 80px;
        justify-content: right;
        height: 35px;
        align-self: baseline;
      }

      [class*="indicatorContainer"] {
        position: fixed;
        width: 35px;
        margin-right: 35px;

        &:last-of-type {
          margin-right: 0;
        }
      }

      [class*="indicatorSeparator"] {
        height: 20px;
        position: fixed;
        margin-right: 35px;
        width: 1px;
      }

      [class*="multiValue"] {
        border-radius: 5em;
        padding: .3em;

        :nth-child(2) {
          border-radius: 8em;
        }
      }
    }

    [class*="menu"] {
      z-index: 100;
    }
  }

  .underlined {
    position: relative;
    padding-bottom: 1em;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -3em;
      right: -3em;

      border-top: 1px solid $primary-dark-white;
    }
  }

  .filter-category {
    margin: 2em 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .subheader {
      margin-bottom: 0.5em;
    }

    .MuiAutocomplete-inputRoot,
    .MuiSelect-outlined {
      background: #fff;
      padding: 5px;
    }
  }

  .MuiAccordion-root {
    background: none;
    box-shadow: none;
    width: 100%;

    &:before {
      display: none;
    }
    
    .MuiAccordionSummary-root {
      .MuiIconButton-label {
        color: $primary-black;
      }
    }

    .MuiAccordionSummary-content {
      font-weight: bold;
    }
  }
}

.range-selection {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $primary-lightest-gray;
  border-radius: 4px;
  position: relative;
  padding: 1em 2em 1em 1em;

  cursor: pointer;

  &:hover {
    border: 1px solid $primary-black;
  }
  p {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: $primary-gray;
  }

  .range-field {
    display: flex;
    align-items: center;

    border-radius: 5px;

    .range {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: .25em .5em;
      border-radius: 5px;
      &:hover {
        background-color: $primary-light-blue;
      }

      .range-value {
        color: $primary-black;
      }

      .remove-range {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: .25em;
        margin-left: 0.5em;
        font-weight: bold;
        color: $primary-black;
        
      }
    }
  }

  .calendar-highlight {
    position: absolute;
    top: 50%;
    right: 1.5em;
    transform: translateY(-50%);
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    background-color: $primary-light-blue;
    z-index: -1;
  }

  .range-selection-label {
    position: absolute;
    top: -0.5rem;
    left: 0.75em;
    background-color: $basic-white;
    padding: 0 0.5em;

    font-size: 0.75rem;
  }
}

.rdrDateRangePickerWrapper {
  width: 80%;
  margin-top: 1em;
  display: flex !important;

  .rdrCalendarWrapper,
  .rdrDateRangeWrapper,
  .rdrMonthAndYearWrapper,
  .rdrMonths,
  .rdrMonth {
    width: 100%;
    min-width: 18rem;
  }

  @media only screen and (max-width: 600px) {
    .rdrStaticRanges {
      display: none;
    }
  }
}

.filter-footer {
  display: flex;
  justify-content: space-between;
  padding-left: 2em;
}
