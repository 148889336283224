@import "../../../styles/variables";

.info-carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;

  button {
    width: 100%;
  }

  .info-carousel-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 324px;

    .carousel-image {
      width: auto;
      max-width: 90%;
      max-height: 90%;
    }

    h3 {
      text-align: center;
      margin: 1em 0;
      font-size: 1.5rem;
      color: $primary-black;
    }

    .carousel-switch-container {
      .arrow-button {
        margin-left: 28px;
        margin-right: 28px;
        padding: 5px;
        border: 2px solid #5f6368;
        border-radius: 10px;
        font-size: 38px;

        &:hover {
          cursor: pointer;
        }
      }
    }
    .circles {
      display: flex;
      .circle {
        border: 1px solid $primary-blue;
        border-radius: 50%;
        height: 0.5rem;
        width: 0.5rem;
        margin: 0.25em;
      }
      .active {
        background-color: $primary-blue;
      }
    }
  }
}
