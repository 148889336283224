@import "../../../styles/variables";

.icon-button{
  cursor: pointer;
}
.help-icon {
  height: 3.5rem !important;
  width: 3.5rem !important;
  color: $primary-blue;
}

.contact-icon {
  color: $primary-blue;
  height: 3rem !important;
  width: 3rem !important;
}

.nav-header-icon {
  color: $primary-blue;

  &:hover {
    cursor: pointer;
  }
}

.nav-blue-icon {
  color: $primary-blue;
}

.social-media-icon {
  height: 2rem !important;
  width: 2rem !important;
}

.blue-icon {
  color: $primary-blue;
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.small-blue-icon {
  color: $primary-blue;
  height: 1rem !important;
  width: 1rem !important;
}

.gray-icon {
  color: $primary-gray;
}

.small-gray-icon {
  color: $primary-gray;
  height: 1rem !important;
  width: 1rem !important;
}

.dark-blue-icon {
  color: $primary-dark-blue;
}

.white-icon {
  color: $primary-white;
  -webkit-filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.7));
}

.personal-icon {
  color: $primary-gray;
  height: 1.25rem !important;
  width: 1.25rem !important;
}

.details-icon {
  color: $primary-black;
  height: 1rem !important;
  width: 1rem !important;
}

.delete-icon {
  color: $primary-red;
  height: 1.5rem !important;
  width: 1.5rem !important;

  &:hover {
    cursor: pointer;
  }
}

.settings-icon {
  color: $primary-white;
  background-color: $primary-blue;
  padding: 5px;
  height: 2rem !important;
  width: 2rem !important;
  border-radius: 5px;
}

.facebook {
  fill: #2e89ff !important;
}

.success-icon {
  color: green;
  width: 1.125rem !important;
  height: 1.125rem !important;
  margin-right: 0.25em;
}

.failure-icon {
  color: red;
  width: 1.125rem !important;
  height: 1.125rem !important;
  margin-right: 0.25em;
}

