@import "../../../styles/variables";

.drop-field {
  border-radius: 4px;
  height: 10rem;
  width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5em 1.5em;
}

.inactive {
  border: 2px dashed $primary-gray;

  button {
    color: $primary-dark-blue;
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0.5em 1em;
    font-size: 1rem;
    border-radius: 5px;
    box-shadow: 0px 3px 6px $box-shadow-color;
    margin-top: 1em;

    &:hover {
      color: $primary-blue;
    }
  }
}

.active {
  border: 2px solid $primary-blue;
  background-color: $primary-light-blue;
}
