@import "../../../styles/variables";

.close-btn {
  position: absolute;
  top: 0.5em;
  right: 1em;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}

.link-share {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    word-break: break-word;
  }
}
