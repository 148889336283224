@import "../../../styles/variables";

.reward-banner {
  border-radius: 12px;
  width: 100%;
  height: 8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.5em;
  background: $primary-dark-white;
  position: relative;
  overflow: hidden;
  margin-bottom: -0.5rem;
  @media screen and (min-width: 780px) {
    justify-content: center;
    height: 7rem;
  }

  h4 {
    font-size: 14px;
  }

  .reward-content {
    position: relative;
    z-index: 4;

  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;

    @media screen and (max-width: 400px) {
      opacity: 0.3;
    }
  }
  h4 {
    color: $primary-dark-blue;
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 1em;
}
