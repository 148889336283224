@import "../../../styles/variables";

.my-jobs-container-main {
  box-sizing: border-box;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;
  height: auto;

  @media only screen
    and (min-width: 768px)
    and (max-height: 1366px)
    and (orientation: portrait)
    and (-webkit-min-device-pixel-ratio: 1.5) {
      height: 100%;
      overflow-y: scroll;
  }

  .jobs-container {
    width: 100%;
    align-items: center;
  }

  .tab-content {
    padding: 15px;
    & > h6 {
      padding-bottom: 15px
    }
  }

  .MuiTab-wrapper {
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-size: .875rem;
  }

  .MuiTabs-flexContainer {
    .MuiTab-textColorInherit {
      align-items: center;
      border: none;
      color: #8d9292;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      padding: 20px;
      text-transform: capitalize;

      &.Mui-selected {
        &,
        & .tab-icon {
          color: $prim-blue;
        }
      }
    }
  }
  .MuiTouchRipple-root {
    display: none !important;
  }

  .tab-icon {
    color: #8d9292;
    height:14px;
    width: 14px;
    margin-right: 5px;
  }

  @media screen and (min-width: 768px) {
    .jobs-container {
      margin-bottom: 30px;
    }
  }

  @media screen and (min-width: 1350px) {
    flex-direction: row;
    align-items: baseline;

    .jobs-container {
      align-items: center;
      margin-bottom: 0px;
      padding: 10px;
      min-height: 500px;
    }
  }

  @media screen and (min-width: 1350px) {
    height: 100%;
  }
}


.application .job-card .card-content button.color-button {
  pointer-events: none;
  font-size: 14px;
  box-shadow: none;
  border-radius: 5px;
}

.space-top {
  padding-top: 50px;
}
.bg-white {
  background: white;
}
.min-h-500 {
  min-height: 500px;
}


.col-xs {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 690px) {
  .col-xs {
    display: flex;
    flex-direction: column;
  }
  .margin-r-10 {
    margin-right: 0;
    margin-bottom: 20px;
  }
}


.text-link {
  background: none;
  border: none;
  color: $primary-blue;
  @media screen and (min-width: 769px) {
    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
}
