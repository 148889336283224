@import "../../../styles/variables";
@import "../../../styles/common";

.job-card.condensed {
    .condensed-job-pill-section {
        .pill {
            background: $primary-light-blue;
            color: $primary-blue;
            margin-left: 0;
            margin-top: 10px;
            text-decoration: none !important;
        }
    }
}