@import "../../../styles/variables";


.forgot-password-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;

  .forgot-password-form-container {
    height: 100%;
    width: 100%;

    h1 {
      color: $primary-black;
      text-align: center;
    }

    .send-button {
      width: 242px;
      height: 46.94px;
      background: $primary-blue;
      border-radius: 10px;
      color: #F7F7F7;
      font-weight: 700;
      font-size: 14px;
      border: none;
      font-size: 14px;
      line-height: 14px;
      align-items: center;
      text-align: center;
      letter-spacing: -0.02em;
    }
  }

  @media screen and (min-width: 768px) {
    .forgot-password-form-container {
      align-items: center;
      background-color: $primary-blue;

      .forgot-password-form {
        background-color: white;
        border-radius: 18px;
        padding: 20px 45px;
        margin-top: -2em;
        width: 60%;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .forgot-password-form-container {
      .forgot-password-form {
        width: 50%;
      }
    }
  }

  @media screen and (min-width: 1235px) {
    .forgot-password-form-container {
      .forgot-password-form {
        width: 40%;
      }
    }
  }
}
