@import "../../../styles/variables";

.phone-verification-consent {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  font-style: italic;
  text-align: center;
}

.phone-verification-consent-link {
  font-size: 12px;
  padding-right: 10px;
  display: inline-block;

  &:last-child {
    padding-right: 0px;
  }
}

.phone-verification-center {
  text-align: center;
}

.phone-verification-left {
  text-align: left;
}