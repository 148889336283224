@import "../../../styles/variables";

.logo-box {
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  .logo {
    width: 45px;
    height: auto;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  }

  .text {
    display: none;
  }

  @media screen and (min-width: 768px) {
    background-image: none;

    .logo {
      margin-right: 18px;
    }

    .text {
      display: block;
      color: white;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 26px;
      letter-spacing: -0.02em;
    }
  }
}
