@import "../../styles/variables";

.nav-header
{
  &.mobile {
    padding: 0;
  }
  .mobile-nav-buttons {
    display: flex;
  }
}
.title-wrapper {
  title-wrapper {
    margin: 8px 15px 0 0
  }
}

.title-wrapper {
  &.mobile {
    height: 56px;

    .header-logo {
      text-align-last: left;
      .logo {
        width: 60px !important;
        height: 60px !important;
      }
    }
  }
}

.mobile-menu-drawer {
  z-index: 0 !important;

  .MuiBackdrop-root {
    background-color: none !important;
  }

  .MuiDrawer-paperAnchorLeft {
    left: 0;
    top: 67px !important;
    right: auto;
    overflow: scroll;
  }
}

.mobile-nav-header {
  .MuiListItem-root.Mui-selected, .MuiListItem-button:hover, .MuiListItem-root.Mui-selected:hover {
    text-decoration: none;
    background-color: #DFEFF8;
  
    .basic-icon {
      color: #DFEFF8;
    }
  }
  
  .MuiListItem-root.Mui-focusVisible {
    background-color: rgba(153, 226, 255, 0.08);
  }  
}