@import "../../../styles/variables";

.favorites {
  margin-top: 2em;
}
.favorite-card {
  box-shadow: 0 3px 6px $box-shadow-color;
  width: 100%;
  max-width: 35rem;
  margin-right: 1em;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  margin-top: 1em;
  z-index: 1;
  .favorite {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5em;
    z-index: 50;
  }
  .favorite-details {
    padding: 0.5em;
    display: flex;
    justify-content: space-between;
    background: white;

    .job-location,
    .job-salary {
      font-weight: 600;
      font-size: 0.875rem;
    }

    .salary {
      p {
        color: $primary-light-gray;

        span {
          color: $primary-black;
          font-size: 1rem;
        }
      }
    }

    .job-title {
      color: $primary-light-gray;
    }
  }
  .job-img {
    width: 100%;
    height: 12rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px 10px 0 0;
      opacity: 0.9;
      object-fit: cover;
      object-position: left 25%;
    }
  }
}

.no-favorites {
  margin: 5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    text-align: center;
  }
}

.loading-favorites {
  margin-top: 20rem;
}

.skeleton-container {
  margin-top: 2em;
  width: 100%;
}
