@import "../../../styles/variables";

.footer-profile-modal {
  display: flex;
  justify-content: space-between;
  padding: 0.75em 1em;
  height: 4rem;
  text-transform: uppercase;
}


button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events:none;
}
