@import "../../../../styles/variables";

.featured-jobs {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  width: 100%;
  height: fit-content;
  min-height: 15rem;
  justify-content: flex-start;
  gap: 1em;
  //   &::-webkit-scrollbar {
  //     display: none;
  //   }

  .loading {
    margin: 0 auto;
  }
}
.featured-card {
  box-shadow: 0 3px 6px $box-shadow-color;
  min-width: 9.5rem;
  width: 10rem;
  height: 12rem;

  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  z-index: 1;

  .favorite {
    position: absolute;
    top: 0;
    right: 0;
    height: 2rem;
    width: 3rem;
    padding: 0.5em;
    z-index: 4;
  }
  .job-details {
    padding: 0.5em;
    display: flex;
    flex-direction: column;
    height: 6rem;

    p {
      font-size: 0.75rem;

    }

    .title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    }


    .job-location,
    .job-salary {
      font-weight: 600;
    }
  }
  .job-img {
    width: 100%;
    height: 6rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px 10px 0 0;
      opacity: 0.9;
      object-fit: cover;
    }
  }
}
