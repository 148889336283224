@import "../../../styles/variables";

.notification-container {
  background-color: $primary-light-blue;
  max-width: $max-container-width;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin: 0 auto;
  border-radius: 8px;

  a {
    text-decoration: none;
  }

  p {
    width: 75%;
  }
}
