@import "../../../../styles/variables";

.references-container {
  width: 100%;
  padding: 0 1em;
}

.reference-form{
  width: 100%;
  max-width: $max-container-width;
}

.reference-card{
  max-width: $max-container-width;
  border: 1px solid $primary-light-gray;
  border-radius: 10px;
  padding: 0 2em 2em 2em;
}
