@import "../../../styles/variables";

.pdf {
  background-color: $primary-white;
  position: relative;

  .iframe-container {
    position: relative;
    overflow: hidden;
    width: 98vw;
    height: 100%;
    margin-top: 2rem;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
}
