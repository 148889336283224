@import "../../../../styles/variables";

.resume-card {
    .card-wrapper { 
        @media only screen and (max-width: 767px) {
          box-shadow: none;
          border-radius: 0;
        }

        margin-top: 1em;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        background-color: white;
        padding: 25px;
        padding: 0.5em 1em;
        margin-bottom: 1em;
        margin-right: 0;
        max-width: 100%;
        border-radius: 8px;
    }

    .resume-card-data {
        display: inline-block;
        border-bottom: 1px solid #EBECEC;

        .details {
            display: inline-flex;
            align-items: center;
        }

        svg {
            color: $primary-blue;
            display: inline-block;
            margin-right: 0.5em;
        }

        button {
            padding: 0;
            text-align: left;
        }
    }


    .resume-buttons {
        display: inline-block;

        .buttons {
            padding: 0.25em 0 0 0;
            display: inline-block;

            &.edit {
                margin-right: 0.5em;

                button {
                    padding-left: 0;
                }
            }
        }
    }
}
