@import "../../../styles/variables";

.progress-bar {
  position: relative;
  height: 10px;
  width: 100%;
  border-radius: 50px;
  background-color: $primary-dark-white;
}
.filler {
  background: $primary-yellow;
  height: 100%;
  border-radius: inherit;
  transition: width 0.1s ease-out;
}
