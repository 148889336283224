@import "../../../../styles/variables";

.password-icon {
  margin-top: 1rem !important;
}

.show-password {
  color: $primary-gray;
  cursor: pointer;
  text-transform: uppercase;
}

.MuiButton-text {
  &.social-login-button {
    border-radius: 50%;
    padding: 20px;

    &.facebook-wrapper {
      background: #3B5998;
      
      .facebook-white {
        fill: #fff;
      }
    }

    &.google-wrapper {
      background: #F1F1F1;
    }
  }
}
