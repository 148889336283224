@import "../../../styles/variables";

.congrats-landing {
  padding: 4em 5%;

  h1,
  h3 {
    color: #020606;
    font-size: 28px;
  }

  p {
    color: #5D6161;
    font-size: 18px;
  }

  .congrats-wrap.MuiGrid-item {
    text-align: center;
    padding-bottom: 18px;
  }

  .paper-wrap {
    width: 100%;
    display: flex;
    justify-content: center;

    .recruiter {
      &_wrap {
        padding: 2em 1em;
        text-align: center;
      }

      &_tags {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 8px 10px;

        .tag {
          background: $primary-light-blue;
          border-radius: 42px;
          color: $primary-blue;
          margin: 0 8px 8px 0;
          padding: 5px 10px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      &_schedule {
        text-align: center;
        margin-bottom: 16px;

        .next-step {
          padding: 1rem 3rem;
        }
      }

      &_jobs {
        .text-link {
          color: $primary-gray;
          font-size: 18px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &.skeleton-wrap {
      .skeleton {
        margin: 0 auto 10px;
      }
    }
  }
}
