@import "../../../../styles/variables";

.personal-information {
  max-width: $max-container-width;

  @media screen and (min-width: 960px) {
    #simple-select-label {
      width: 150%;
    }
  }
}

.information-wrapper.desktop-view .profile-fields div.personal-details-container > .card-wrapper {
  .MuiChip-root {
    height: 25px;
    background: $primary-light-blue;
    margin-right: 0.5em;
    margin-top: 0.5em;

    .MuiChip-label {
      font-size: .875rem;
      line-height: 1.25rem;
      color: $primary-blue;
    }
  }

  .personal-wrapper {
    display: grid;
    align-content: center;
    grid-template-columns: 1fr minmax(0, 2fr);
    min-width: 0;
    min-height: 0;

    .profile-photo {
      width: 80px;
      height: 80px;

      @media only screen and (min-width: 768px) {
        width: 120px;
        height: 120px;
      }
    }

    .profile-details {
      margin-left: 0.5em;
      max-width: 100%;


      .name {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
      }

      .detail {
        display: flex;
        align-items: flex-start;

        .email-wrapper{
          width: 100%;
        }

        .phone-wrapper, .email-wrapper {

          .verify-contact{
            color: #3886c5;
            text-decoration: underline;
            text-underline-position: under;
            cursor: pointer;

            &:hover{
              text-decoration: none;
            }
          }

          .code-input {
            margin-left: 10px;
            font-size: 8px !important;

            .resend {
              font-size: 14px;
              margin-top: 5px;
              color: $primary-blue;
              text-decoration: underline;
              text-underline-position: under;
              cursor: pointer;
    
              &:hover{
                color: $primary-dark-blue;
              }
            }
          }

          .confirm-success {
            display: flex;

            svg {
              color: green;
            }
          }
      
          .verifying-failure, .confirm-failure {
            color: red;
          }

          .verifying-failure {
            margin-left: 5px;
          }
        }

        .email {
          min-width: 0;
        }
      }
    }

    .notifications-active-error {
      color: red;
    }
  }
}

.MuiDialog-container {
  .MuiDialogTitle-root {
    padding-bottom: 0;

    .verify-close {
      position: absolute;
      top: 0;
      right: .5em;
      font-size: 1rem;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .confirm-failure {
    display: flex;
    margin-top: 5px;

    svg {
      color: red;
    }

    p {
      color: $primary-black;
      margin-left: 5px;
    }
  }

  .resend {
    margin: 2em 0 1em;
  }
}
