@import "../../../styles/variables";

.refer {
  justify-content: space-around;
  background-image: $background-gradient;
  color: $primary-white;
  width: 100%;
  max-height: 100%;
  padding: 0 2em;
  overflow-x: hidden;
  h1 {
    text-align: center;
  }
  h1,
  p {
    color: $primary-white;
    margin-top: 1em;
  }

  p {
    font-size: clamp(1rem, 5vw, 1.375rem);
    max-width: 35ch;

    span {
      font-size: 1.625rem;
      font-weight: 600;
    }
  }

  .refer-options {
    display: flex;
    gap: 1em;
  }

  .refer-option {
    background-color: $primary-white;
    padding: 1em;
    border-radius: 20px 5px 20px 20px;
    box-shadow: 0 3px 6px $box-shadow-color;
  }

  .modal-img {
    width: 90%;
    max-width: 35rem;
    min-width: 18rem;
    margin: 1em;

    img {
      width: 100%;
      height: auto;
    }
  }
}
