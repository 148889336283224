@import "../../../../styles/variables";

.skill-details-card {
    .details-card {
        display: block;
    }

    .skill-details-description {
        margin-top: 16px;
        margin-bottom: 8px;
    }

    .skill {
        margin-bottom: 16px;

        .name {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
       }

        .progress-card-grid-container {
            display: inline-flex;
            align-items: center;

            .completion-date {
                font-style: italic;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: #B0B2B2;
            }
        }

        .progress-bar {
            margin-right: .5em;
        }

        .progress {
            color: #969696;
            background-color: transparent;
            padding: 0;
        }

        .progress-header {
            color: #3b3e40;
            margin-bottom: 1em;
            font-size: 18px;
        }

        .progress-card-grid-container {
            .MuiCheckbox-colorSecondary.Mui-disabled {
                color: #6dd19d;
            }

            .filler {
                background: #E89315;
            }
        }

        .percentage-label {
            font-style: italic;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #B0B2B2;
        }

        .manage-skill-button-container {
            text-align: right;
            display: flex;
            flex-direction: row;

            button {
              min-width: auto;
              padding: 4px 4px;
            }
        }
    }
}
