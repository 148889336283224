@import "../../../../styles/variables";

.user-details {
    .search-type-label {
        &.MuiFormLabel-filled {
            display: none;
        }
    }

    .MuiPaper-elevation1 {
        box-shadow: none;
    }

    .search-header-section {
        padding: 0 .5em;

        .add-new-candidate {
            text-align-last: right;
        }

        .search-name-fc {
            width: 100%;
            
            .search-type-label {
                margin-top: -12.5px;
                padding-left: 10px;
            }

            .search-name-select {
                .MuiSelect-select.MuiSelect-select {
                    padding: 10px;
                }
            }
        }

        .auth-provider-fc {
            width: 100%;
            
            .auth-provider-label {
                margin-top: -12.5px;
                padding-left: 10px;
            }

            .auth-provider-select {
                .MuiSelect-select.MuiSelect-select {
                    padding: 10px;
                }
            }
        }
    }

    .MuiIconButton-root {
        border-radius: 2px;

        &.Mui-disabled {
            p {
                opacity: 0.35;
            }
        }

        &.text-button {
            display: inline-block;
            padding: 5px 10px;
            align-self: center;
            box-sizing: border-box;
            border-radius: 2px;
            width: 48%;
            font-size: 16px;
            
            &.Mui-disabled {
                background: #8D9292 !important;
                color: White !important;
            }

            @media screen and (max-width: 1460px) {
                &.view-user, &.delete-user, &.become-user {
                    width: 100% !important;
                    margin-bottom: 4px;
                }
            }

            @media screen and (max-width: 959px) {
                &.add {
                    width: 100%;
                    max-width: 100%;
                }

                &.search {
                    width: 49% !important;
                    max-width: 49% !important;
                    margin-right: 0 !important;
                }

                &.reset {
                    width: 49% !important;
                    max-width: 49% !important;
                    margin-right: 0 !important;
                    margin-left: 4px;
                }
            }
            @media screen and (min-width: 960px) {
                &.add {
                    max-width: 126px;
                }
            }
            
            &.add {
                min-width: 140px;
                padding: 8px 10px;
                background: #008DD1;
                color: white;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                font-size: 16px;
                margin-right: 4px;

                &:hover {
                    cursor: pointer;
                    background-color: #008dd1cf;
                }
            }

            &.search {
                min-width: 105px;
                background: linear-gradient(90deg, #3A87C7 0%, #66CFFA 100%);
                padding: 8px 10px;
                border-radius: 8em;
                color: white;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                font-size: 16px;
                margin-right: 4px;

                &:hover {
                    cursor: pointer;
                    background-color: #008dd1cf;
                }
            }

            &.reset {
                background: white;
                color: #7c7c7c;
                width: 80px;
                min-width: 80px;
                padding: 8px 10px;
                border-radius: 8em;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                font-size: 16px;
                margin-right: 4px;

                &:hover {
                    cursor: pointer;
                    background-color: #d0f1ff;
                }
            }
        
            &.action-button {
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                width: 30%;
                margin-right: 4px;
            }

            &.view-user {
                color: #008DD1;
                border: 1px solid #008DD1;
            }

            &.delete-user {
                color: #E07063;
                border: 1px solid #E07063;
            }

            &.become-user {
                color: #03c03c;
                border: 1px solid #03c03c;
            }

            &.view-user, &.delete-user, &.become-user {
                &:disabled {
                    color: gray;
                    border: 1px solid gray;
                }
            }
        
            &:hover {
                cursor: pointer;
                background-color: rgba(0, 0, 0, .04);
            }
        }  
    }
    .user-details-card {
        .detail-card-container {
            display: inline-flex;
            padding: 1em;
            border-bottom: 1px solid #F5F7F7;

            .detail-card-header {
                display: inline-flex;
            }
        }

        .user-details {
            display: inline-flex;
            padding: 1em;
            border-bottom: 1px solid #F5F7F7;

            @media screen and (max-width: 959px) {
                display: contents;
            }

            &:last-child {
                border-bottom: none;
            }
            
            .detail-card-content {
                display: table-cell;
                align-self: center;

                @media screen and (max-width: 959px) {
                    display: block;
                }

                &.email {
                     p {
                        white-space: nowrap; 
                        width: 85%; 
                        overflow: hidden;
                        text-overflow: ellipsis; 
                     }
                }
            }
        }
    }

    .verify-checkmark {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background-color: green;
        align-items: center;
        justify-content: center;
        margin-left: 2.5px;
        margin-right: 2.5px;
    
        .verified-check {
          color: $primary-white;
          height: 1rem !important;
          width: 1rem !important;
          padding-top: 2px;
        }

        &:hover {
            cursor: pointer;
        }
      }

    .corrupted-user {
        padding: 2.5px;
        font-size: 10px;
    }  
}