@import "variables";

.App {
  text-align: center;
}

iframe
{
    display: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  font-family: 'Ubuntu', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
button {
  font-family: 'Ubuntu', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

h1 {
  font-size: 56px;
  color: $prim-black;
}

h2 {
  font-size: 40px;
  color: $prim-black;
}

h3 {
  font-size: 36px;
  color: $prim-black;
}

h4 {
  font-size: 28px;
  color: $prim-black;
}

h5 {
  font-size: 24px;
  color: $prim-black;
}

h6 {
  font-size: 20px;
  color: $prim-black;
}

p {
  font-size: 16px;
  color: $prim-black;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

a {
  display: block;
  color: $primary-blue;
  text-decoration: none;
}

ul {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

button {
  cursor: pointer;
}

.caption {
  color: $prim-gray;
  font-size: 12px;
  margin-bottom: 1em;
}

.icon-button{
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

.edit-card {
  width: 100%;
  margin: 1em 0;
  padding: 1em 0;
  position: relative;
  &:not(:last-child):after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -4em;
    right: -4em;
    height: 2px;
    background: $primary-white;
    box-shadow: 2px 2px 4px $box-shadow-color;
  }
}

.edit-card > div:not(:last-child) {
  margin: 1em 0;
}

.subheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: $basic-white;
  height: 3.8rem;
  box-shadow: 3px 0px 6px $box-shadow-color;
  z-index: 100;

  .footer-button {
    display: flex;
    align-items: flex-start;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

//Global Typography
.alert-danger {
  color: $primary-red;
}

.input-icon {
  color: $primary-gray;
}

.center-align{
  text-align: center;
}

.alternatives-header {
  position: relative;
  font-size: 1.125rem;
  &::before,
  &::after {
    position: absolute;
    content: "";
    top: 50%;
    border-top: 1px solid $primary-dark-blue;
    width: 4rem;
    transform: translateY(-50%);
  }
  &::before {
    left: -5rem;
  }
  &::after {
    right: -5rem;
  }
}

//Error Boundary
.error-boundary{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

//Global Profile Details Styles
.details-card {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;

  &:not(:first-child) {
    margin-top: 0.5em;
  }

  .placeholder-logo {
    max-width: 5rem;
    max-height: 5rem;
    min-height: 3rem;
    min-width: 3rem;
    background-color: $primary-blue;
    border-radius: 50%;
    margin-right: 1rem;
  }

  .details{
    width: 100%;
    .file {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }


}

//Global Modal Styles
.modal {
  .white-background {
    background-color: $basic-white;
  }
  .modal-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    margin-bottom: 2em;
    overflow-y: auto;
    overflow-x: hidden;



    h1 {
      font-size: clamp(1.25rem, 7vw, 1.875rem);
    }
  }
}

.modal-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  .close-btn {
    position: absolute;
    top: 1rem;
    right: 2rem;
    font-size: 2rem;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      color: $primary-gray;
    }
  }
}

.MuiTypography-body1 {
  font-family: "Ubuntu", sans-serif !important;
}



// Hide arrows for number input

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
