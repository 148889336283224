@import "../../../styles/variables";

.refer-banner {
  border-radius: 12px;
  width: 100%;
  height: 8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-image: $logo-gradient;
  position: relative;
  @media screen and (min-width: 780px) {
    height: 7rem;
    justify-content: center;
  }

  .refer-content {
    margin-left: 7rem;
    @media screen and (min-width: 780px) {
      margin-left: 6rem;
    }
  }

  h5,
  h6 {
    color: white;
    z-index: 10;
  }

  .inverse-button {
    padding: .5rem .75rem;
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 1em;
}

.refer-img {
  width: 7rem;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  border-radius: 12px;

  img {
    position: absolute;
    top: 0;
    right: 1rem;
    width: 26.875rem;
  }
}
