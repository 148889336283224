@import "../../../styles/variables";

.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;

  button {
    width: 100%;
  }

  .landing-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 25rem;

    .carousel-image {
      width: auto;
      max-width: 100%;
      max-height: 100%;
    }

    h3 {
      text-align: center;
      margin: 1em 0;
      font-size: 1.5rem;
      color: $primary-black;
    }

    .circles {
      display: flex;
      .circle {
        border: 1px solid $primary-blue;
        border-radius: 50%;
        height: 0.5rem;
        width: 0.5rem;
        margin: 0.25em;
      }
      .active {
        background-color: $primary-blue;
      }
    }
  }

  .links {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3.1825em;

    > *:not(:first-child) {
      margin-top: 0.75em;
    }

    .link-button {
      a {
        font-size: 1.25rem;
        text-decoration: underline;
        text-underline-position: under;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
