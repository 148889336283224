@import "../../../styles/variables";

.MuiGrid-container {
  .refer-a-friend {
    padding: 12px;

    &-card {
      background: url('../../../images/refer-bg2.svg') no-repeat;
      background-size: cover;
      border-radius: 1em;
      box-shadow: none;
      padding: 16px;

      h6 {
        margin: 0;
      }

      p {
        margin: .5em;
      }

      .caption {
        max-width: 228px;
        padding-bottom: 1em;
      }

      input {
        border: 0;
        display: block;
        font-size: 18px;
        max-width: 228px;
        padding: 16px;
        width: 100%;
      }

      .input-wrapper {
        position: relative;

        .no-fill {
          cursor: pointer;
          position: absolute;
          right: 30%;
          top: 50%;
          transform: translateY(-50%);

          @media screen and (min-width: 768px) {
            right: 38%;
          }

          @media screen and (min-width: 960px) {
            right: 12%;
          }
          svg {
            fill: none;
          }
        }
      }

      .color-button {
        margin: .5em 0;
        max-width: 228px;
        padding: .5em;
        width: 100%;
      }
    }
  }
}
