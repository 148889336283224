@import "../../../../styles/variables";
.preferences {
  padding-bottom: 2rem;
  .preference {
    margin: 1em 0;

    .question {
      margin-bottom: 0.5em;
      display: block;
    }

    .MuiFormControl-marginNormal {
      margin-top: 0;
    }
  }
}
.question {
  margin: 0.5em 0;
  font-size: 1.125rem;
  font-weight: 600;
}

.qualities {
  margin-top: 1em;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;

  @media screen and (min-width: 780px) {
    grid-template-columns: repeat(4, 1fr);
  }

  .quality {
    box-shadow: 0 3px 6px $box-shadow-color;
    padding: 0.5em 0.25em 0.25em 0.25em;
    height: 5.2rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5em;
    text-align: center;
    max-width: 10rem;
    cursor: pointer;
  }

  .selected {
    background: $primary-blue;
    color: white;
    p {
      color: white;
    }
  }

  .unselected {
    background: $primary-white;
  }

  .startOptions {
    width: 100%;
    .startOption {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      background: yellow;
    }
  }
}
