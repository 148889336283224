@import "../../styles/variables";

.dialog-buttons{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.cancel-button {
    background: none;
    width: 180px;
    height: 46.94px;
    color: $primary-blue;
    font-weight: 500;
    font-size: 15px;
    border: none;
    font-size: 14px;
    line-height: 14px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    @media screen and (min-width: 1024px) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

.logout-button {
    width: 180px;
    height: 46.94px;
    margin: 0;
    letter-spacing: -0.02em;
    font-size: 15px;
  }

.MuiDialog-paperScrollPaper {
    padding: 20px 24px;
}
