.dashboard-box {
  &.care-team {
    @media screen and (max-width: 1279px) {
      padding-top: 0;
      margin-top: 25px;
    }
  }

  .careteam {
    &-banner {
      box-shadow: none;
    }

    &-info {
      padding: 1em;


      p {
        color: #5d6161;
        font-size: 16px;
        margin-bottom: .5em;
      }

      a {
        display: inline-block;
      }

      .email-button {
        background: #fff;
        box-shadow: 0px 0px 0px 2px #008dd1;
        color: #008dd1;
        margin-right: .5em;
      }
    }

    &-img {
      img {
        object-fit: cover;
      }
    }
  }
}
