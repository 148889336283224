@import "../../../styles/variables";
.jobs-detail-container {
  .info-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        padding: 0 2em;

        .top-info {
          .sub-info {
            margin-top: 0;
          }
        }
    }

    @media screen and (min-width: 1280px) {
      &.MuiGrid-root {
        max-width: calc(25% - 20px);
        margin: 0 10px;
      }
    }

    @media screen and (max-width: 1279px) {
      padding: 0 15px;
    }

    .dashboard-box.resources {
      width: 100% !important;
      padding: 3em 2em;
    }

    .sub-info {
      margin-top: 4px;
      margin-bottom: 20px;
    }

    .dashboard-box {
      background: white;
      padding: 1em 1em;
    }
  }

  .details-grid-container {


    @media screen and (max-width: 768px) {
      padding-top: 0;

      .job-image {
        margin-top: 0;
      }

      .save-button {
        padding: .54em 4em !important;
      }

      .details-container {
        margin-bottom: 2.25em !important;
      }
    }
    .MuiSkeleton-text{
      transform: scale(1)
    }


    .MuiButton-label {
      color: #4d92cb;
    }

    .details-wrapper {
      @media screen and (max-width: 768px) {
        .save-button-sticky {
          width: 100%;
          background:#ffffffb5;
          position: absolute;
          bottom: 0;
          right: 0;
          display: block;
        }

        .save-button-container {
          width: 100%;
          padding: 0 2em;
        }

          .save-button {
            width: 100%;
            text-align: center;
            justify-content: center;
            float: initial !important;
            background-color: #008DD1 !important;
          }
      }

      background: white;

      .save-button-sticky {
        @media screen and (min-width: 960px) {
          width: 260px;
        }
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
        z-index: 100;
      }

      .save-button-container {
        text-align-last: end;
        margin: 0 auto;
        max-width: 1440px;
        width: 100%;


        .save-button {
            margin-right: 2em;
            margin-bottom: 1em;
            margin-top: 1em;
            float: right;
            font-weight: 200;
            padding: .7em;
            border-radius: 1em;
            text-transform: initial;

            &:focus {
              border: none;
              outline: none;
            }
        }
      }

      .save-button-text {
        align-self: center;
      }


      .quick-save-button-container {
        @media screen and (max-width: 768px) {
          .save-button {
            width: 100%;
            padding: 0em !important;
          }
        }
        padding-right: 2em;

       .save-button {
        border-radius: 1em;
        font-weight: 200;
        text-transform: none;
        height: 35px;
        margin-top: 1em;
       }
      }

      .social-icons {
        text-align-last: end;
        align-self: center;

        &.mobile {
          padding: 0 0 2em;

          .mobile-back {
            padding-left: 0;
            text-align-last: auto;
          }
        }

        .footer-button {
          display: inline-flex;

          :hover {
            cursor: pointer;
          }
        }

        .social-icons-container {
          align-self: center;
        }
      }
    }

  }
  .save-button {
    background-color: #008DD1 !important;
    padding: 1.25em 1.25em;
    border-radius: 3em !important;
    width: 180px;
    text-align: center;
    justify-content: center;

    p {
      color: white;
      font-size: 18px;
      font-weight: 400;
    }
  }
}

.job-image {
  margin-top: 0;
  width: 100%;
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;

  img {
      flex-shrink: 0;
      min-width: 100%;
      min-height: 100%;
      margin: 0;
      object-fit: cover;
  }
}

.job-section {
  margin-bottom: 2em;

  &:last-of-type {
    padding-bottom: 50px;
  }

  li,
  p {
    font-size: 0.875rem;
  }

  ul {
    margin-left: 1em;
    margin-top: 0.5em;
  }

  .pill {
      background: $primary-light-blue;
      color: $primary-blue;
      margin-left: 0;
      margin-top: 10px;
      text-decoration: none !important;
  }

  .specialty-list {
    margin-top: 0.5em;
    display: grid;

    .specialty-list-item {
      display: inline-flex;

      p {
        padding: 0 5px;
      }
    }

    svg {
      height: 1.45rem!important;
      width: 1.15rem !important;
      color: white;
    }
  }

  .job-shift {
    margin-top: 0.5em;

    .shift-data {
      display: inline-flex;

      p {
        padding: 0 5px;
      }
    }
  }

  .job-location-container {
    .location-text {
      display: inline-flex;
      font-size: 0.875rem;
    }

    .content-tag {
      padding: 0 4px;
      display: inline-flex;

        &:before {
          content:" • ";
      }
    }
  }

  .job-title-container {
    margin-top: 1em;

    .job-title {
      margin-right: .5em;
      display: inline-flex;
    }

    .salary-sub-description {
      font-size: 10px;
      font-style: italic;
    }

    .salary {
      display: inline-flex;
    }
  }


  &.location {

    p {
      color: $primary-gray;
    }

    h3 {
      margin-bottom: .5em;
    }

  }

  h3 {
    color: #44444f;
  }

  .description-text {
    padding: 1.5em 0 0 0;
    color: $primary-gray;
    font-size: 0.875rem;
    line-height: 130%;
    span {
      color: #44444f !important;
    }
  }
}

.details-wrapper {
  padding: 1em 2em;

  h5 {
    margin: .35em 0;
  }
}

.job-summary {
  .salary {
    color: $primary-black;
  }
}

.job-time {
  list-style: none;
  display: flex;
  width: auto;
  margin-top: 1em;


  li {
    width: auto;
    border: 1px solid $box-shadow-color;
    padding: 15px 25px;

    &:nth-of-type(1) {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:nth-of-type(2) {
      border-left: none;
    }

    &:last-of-type {
      border-right: 1px solid $box-shadow-color;
      border-left: none;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    li.p-4 {
      border-top: 1px solid $box-shadow-color;
      border-right: 1px solid $box-shadow-color;
      border-bottom: 1px solid $box-shadow-color;
      border-left: 1px solid $box-shadow-color;
    }
  }

  .detail-category {
    color: $prim-black;
    text-transform: uppercase;
    font-size: 0.825rem;
    margin-top: 8px;
    width: 100%;
    font-weight: 500;
  }
  .detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 0.25em;
    color: $primary-gray;

    h3 {
      color: $primary-gray;
    }

    svg {
      color: $primary-gray;
    }
  }
}

.requirement-icon {
  color: green;
  width: 1.125rem !important;
  height: 1.125rem !important;
  margin-right: 0.25em;
}

.requirement-list {
  list-style: none;
  margin-left: -2.5em;
  margin-top: 0;
  li {
    display: flex;
    margin-bottom: 0.25em;
  }
}

.footer-job-detail {
  justify-content: space-between;
  padding-left: 1em;
}

.zap-icon {
  margin-left:5px;
}

.save-button {
  &:focus {
    border: none;
    outline: none;
  }

  p {
    color: white;
    font-size: 18px;
    font-weight: 400;
  }
}


.job-view main.main-content {
  margin: 4rem 0 0 0;
  max-width: 100%;
}



.back-btn {
  position: absolute;
  margin-top: -40px;
  margin-left: 15px;
  z-index: 100;
}
