@import "../../../../styles/variables";

.professional-summary-edit {
  width: 100%;
  max-width: $max-container-width;

  .text-field-footer {
    display: flex;
    justify-content: space-between;
  }
}

.summary-wrapper {
  width: 90%;
}

.summary {
  text-align: left;
  padding-bottom: 1em;
}

.summary-details {
  a {
    cursor: pointer;
    font-size: 14px;
    padding-left: 1.5em;
  }
}
