@import "variables";

.absolute {
    position: absolute;
}

.bg-white {
  background-color: white;
}

.block {
    display: block;
}

.border {
  border-width: 1px;
  border-style: solid;
}

.border-gray-50 {
  border-color: #e5e7eb;
}

.border-b-1 {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.btn {
  background: #008dd1;
  border-radius: 32px;
  border: none;
  color: white;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-shadow: none;
  transition: all .2s ease;
  font-weight: 500;
  &.secondary {
    background: white;
    color: #008dd1;
    border: 2px solid #008dd1;
  }
  @media screen and (max-width: 767px) {
    color: #008dd1;
    background: none;
    &.secondary {
      border: none;
      color: #ca5852;
    }
  }
}

.btn-sm {
  width: 150px !important;
}

.btn-md {
  width: 100% !important;
}

.btn-lg {
  width: 350px !important;
}

.capitalize {
  text-transform: capitalize;
}

.cover {
  object-fit: cover;
}

.display-contents {
  display: contents !important;
}

.flex {
  display: flex;
}

.flex-1 {
    flex: 1 1 0%;
}

.flex-col {
  flex-direction: column;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 600;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2.5em !important;
}

.h-12 {
  height: 3rem;
}

.italic {
  font-style: italic;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-right {
  justify-content: right;
}

.inline-block {
  display: inline-block;
}

.inline-flex {
  display: inline-flex;
}

.inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.justify-between {
  justify-content: space-between;
}

.leading-6 {
  line-height: 1.5rem;
}

.margin-r-10 {
  margin-right: 10px !important;
}

.margin-l-10 {
  margin-left: 10px;
}

.max-w-md {
  max-width: 28rem;
}

.min-w-0 {
    min-width: 0;
}

.m-2 {
  margin: 0.5rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-8 {
  margin-left: 2rem;
  &-sm {
    @media screen and (max-width: 767px) {
      margin-left: 2rem;
    }
  }
}

.mr-2 {
  margin-right: 0.5rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.no-shrink {
  flex-shrink: 0;
}

.pill-blue {
  background: #dfeff8;
  color: #008dd1;
  display: inline-flex;
  border-radius: 50px;
  margin: 5px 5px 0 5px;
  padding: 0.125rem 0.625rem;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-4 {
  padding: 1rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-4 {
  padding-left: 4px;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-4 {
  padding-top: 1rem;
  &-sm {
    @media screen and (max-width: 767px) {
      padding-top: 1rem;
    }
  }
}

.pt-6 {
  padding-top: 1.5rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.relative {
    position: relative;
}

.round {
  border-radius: 50%;
}

.rounded-md {
  border-radius: 0.375rem;
}

.shadow {
  box-shadow: 0 3px 6px $box-shadow-color;
  &-md {
    @media screen and (min-width: 768px) {
      box-shadow: 0 3px 6px $box-shadow-color;
    }
  }
}

.size-12 {
  height: 3rem;
  width: 3rem;
  max-width: 100%;
}

.size-full {
  height: 7em;
  width: 100%;
  max-width: 100%;
}

.space-x-3 {
  margin-left: 0.75rem;
}


.relative {
  position: relative
}

.tabs-container {
  button {
    font-family: "Ubuntu", sans-serif;
    align-items: center;
    border: none;
    color: $primary-lightest-gray;
    line-height: 24px;
    padding: 20px;
    text-transform: capitalize;

    .MuiTab-wrapper {
      font-style: normal;
      font-weight: 500;
      font-size: .875rem !important;
    }

    &.Mui-selected {
      color: $primary-blue;
    }
  }
}

.text {

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }

  &-left {
    text-align: left;
  }
}

.text-blue-500 {
   color: $primary-blue;
}

.text-gray-200 {
  color: #e5e7eb;
}

.text-gray-400 {
  color: #9ca3af;
}

.text-gray-500 {
  color: #6B7280;
}

.text-gray-900 {
  color: #111827;
}

.text-red-600 {
  color: #dc2626;
}

.text-red-700 {
  color: #b91c1c;
  &:hover {
    text-decoration-color: #b91c1c;
  }
}

.text-hover {
  &:hover {
    cursor: pointer !important;
    text-decoration: underline;
  }
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-md {
  font-size: 1rem;
  line-height: 1.50rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-xxs {
  font-size: .5rem;
  line-height: 0.75rem;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.uppercase {
  text-transform: uppercase;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-12 {
  width: 3rem;
}

.w-full {
  width: 100%;
}

.w-1\/8 {
  width: 16.66%;
}

.w-7\/8 {
  width: 83.44%;
}

.w-1\/3 {
  width: 33.33%;
}

.w-2\/3 {
  width: 66%;
}

// Show / Hide Responsive

.show-xs {
  display: none;
}

.hide-xs {
  display: block;
}

body .hide-sm {
  display: inline-block;
}

.hide-md-sm {
  display: inline-block;
}

.form-control-md {
  height: 350px;
  overflow: hidden;
  overflow-y: scroll;
}

@media screen and (max-width: 767px) {
  body .hide-sm {
    display: none;
  }
}

@media screen and (max-width: 690px) {
  .show-xs {
    display: block;
  }
  .hide-xs {
    display: none;
  }
  .hide-md-sm {
    display: none;
  }
}
