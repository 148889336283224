.license-details {
  .add {
    margin: 1em 0;
  }
  
  .license-docs{
    margin-top: 2em;
  
    h4 {
      margin: 0;
    }
  }
  
  .license-data {
    display: inline-block;

    button {
      padding: 0;
    }
  }
}

