@import "../../../styles/variables";

.delete-modal {
  position: relative;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  margin: 10em auto;
  background-color: $primary-white;
  outline: none;
  h1 {
    font-size: clamp(1.25rem, 7vw, 1.875rem);
  }

  .close-modal {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1em;
    font-weight: 600;
    cursor: pointer;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 2em;
  }

  .saving {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-top: 1em;
    }
  }
}
