@import "../../../../styles/variables";

.certificate-data {
  display: inline-block;

  button {
    padding: 0;
  }
}

.certification-card {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2em 0;

  .details {
    width: 75%;
    .filename {
      
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  span {
    color: $primary-blue;
  }

  &:first-child {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -3em;
      right: -3em;
      border-top: 1px solid $primary-light-blue;
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -3em;
    right: -3em;
    border-top: 1px solid $primary-light-blue;
  }
}
