@import "../../../styles/variables";

.information-toggle-options {
  .add-tile-button {
    margin-top: 1em;
  }
}

.dashboard-main-container {
  @media screen and (min-width: 1280px) {
    padding-left: 1.5em;

    .dashboard-main-content {
      padding-right: 1.5em;
    }
  }

  .dashboard-sidebar {
    @media screen and (min-width: 1280px) {
      padding: 2.75em 2em;
      background: white;

      .detail-toggle-container {
        width: 100%;
      }
    }
  }

  .detail-checkbox-container {
    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  .react-multi-carousel-dot button {
    border-color: #efefef;
  }

  .react-multi-carousel-dot button:hover:active {
    background: $primary-lightest-gray;
}

  .react-multi-carousel-dot--active button {
    background: $primary-lightest-gray;
    border-color: $primary-lightest-gray;
  }

  .dashboard-main-content {
    .tab-wrapper {
      padding: 1em 0;

      .MuiTabs-indicator {
        display: none;
      }

      .MuiTabs-flexContainer {
        .MuiTab-textColorInherit {
          border: 1px solid $prim-light-gray;
          border-radius: 50px;
          color: $prim-light-gray;
          font-size: 14px;
          margin-right: 4px;
          min-height: auto;
          padding: 4px 8px;
          text-transform: capitalize;

          &.Mui-selected {
            border-color: $prim-blue;
            color: $prim-blue;
          }
        }
      }
    }
  }
}

.dashboard-box {
    max-width: 70rem;
    padding-top: 2em;
    margin: auto;

    @media screen and (max-width: 600px) {
      padding-top: 0;

      .subheader {
        display: none;
      }
    }

    &.resources {
      @media screen and (max-width: 1279px) {
        width: 70rem;
        padding: 3em 2em;
      }
    }

    &.MuiGrid-spacing-xs-3 {
      @media screen and (min-width: 1168px) {
        margin-left: auto;
        margin-right: auto;
      }

      @media screen and (min-width: 1280px) and (max-width:1549px) {
        margin-left: -8px;
        margin-right: -8px;
      }

    }

  &.welcome {
    width: 100%;
    max-width: 100%;
    padding-top: 5px;

    @media screen and (max-width: 959px) {
      > div {
        padding-right: 0;
        padding-left: 0;
      }

      margin: 0;
      flex-direction: column-reverse;

      .user-date {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  h1 {
    font-size: 32px;
    color: $primary-black;
  }

  .today {
    color: $primary-gray
  }

  .subheader {
    padding-left: 8px;

    h5 {
      .text-link {
        font-size: 14px;
        padding-left: 16px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .refer-banner > .refer-content {
    transform: scale(0.8);
    margin-left: 5rem;
  }
  .reward-banner {
    padding-left: 2em;
    & > .reward-content {
      transform: scale(0.9);
    }
  }
}

.dashboard-card {
  margin-top: 1em;
  display: flex;
  width: 100%;

  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  box-shadow: 0 3px 6px $box-shadow-color;
  border-radius: 9px;

  .arrow {
    border-left: 1px solid $primary-dark-white;
    padding-left: 1rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.matched-jobs .job-card {
    background: white;
    box-shadow: 0 2px 3px #cccccc50;
}
.matched-jobs.highlight {
  .job-card {
    background: url('../../../images/refer-bg2.svg') no-repeat;
    background-size: cover;
    box-shadow: none;
  }
  .react-multiple-carousel__arrow {
    background: white;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.16);
  }
}

@media screen and (max-width: 959px) {
  .dashboard-box .subheader + div > a {
    & > .job-card {
      border-top: 1px solid #e6e6e6;
    }
    &:first-child .job-card {
      border-top: none;
    }
  }
}


.favorite {
  min-width: 30px;
}

@media screen and (min-width: 768px) {
  .favorite:hover {
    svg {
      color: red;
      transform: scale(1.1);
      transition: all .3s ease-in-out;
    }
  }
}


.main-content > .MuiGrid-root {
  & > .MuiGrid-item {
    margin-bottom: 25px;
  }
}
