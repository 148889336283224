@import "../../../styles/variables";

.resources {
  margin-top: 1em;

  .resource {
    display: flex;
    margin-bottom: 1.5em;
    cursor: pointer;
    width: fit-content;
    p {
      color: $primary-blue;
      margin-left: 1em;
    }
  }
}
