@import "../../styles/variables";

.layout {
  width: 100%;
  background: $background-off-white;

  .job-details-section {
    .main-content {
      &.nav-control {
        padding-left: 0;
      }
    }

    .layout-wrapper {
      padding-right: 0;
    }
  }

  @media screen and (min-width: 1236px) {
    .main-content {
      &.nav-control {
        padding-left: 240px;
      }
    }
  }
  @media screen and (min-width: 960px) and (max-width: 1235px){
    .main-content {
      &.nav-control {
        padding-left: 240px;
      }
    }
  }
}

.layout-wrapper {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (min-width: 1350px) {

    &.profile-view {
      padding: 0 0.5em;
    }
  }

  @media screen and (min-width: 1235px) and (max-width: 1349px) {

    &.search {
      padding-right: 0;
    }


    &.home-view {
      padding: 1.5em 1.5em 0 1.5em;
    }

    &.skill-view {
      padding: 0;
    }
  }


  @media screen and (min-width: 1235px) and (max-width: 1279px) {
    &.profile-view {
      padding: 0 1.5em;
    }

    &.search {
      padding-right: 0;
    }
  }

  @media screen and (min-width: 1278px) and (max-width: 1349px) {
    &.home-view {
      padding: 0;
    }

    &.profile-view {
      padding: 0 0.5em;
    }

    &.search {
      padding: 0;
    }
  }

  @media screen and (min-width: 960px) and (max-width: 1234px) {
    &.search {
      padding: 0 0 0 1.5em;
    }


    &.home-view {
      padding: 1.5em 1.5em  0;
    }

    &.profile-view {
      padding: 0 1.5em;
    }
  }
}

.nav-title {
  display: inline-block;
  vertical-align: top;
  margin: 5px;
}

.header-link {
  padding: 0;
}

.header-logo {
  display: inline-block;
  text-align-last: center;
  height: auto;
  width: 100%;
  max-width: 180px;
  margin-top: 16px;

  svg {
    width: 208px;
  }

  .logo {
    width: 100%;
    height: auto;
  }
}

.mobile-link, .desktop-link {
  width: 100%;
}

.nav-link {
  padding: 12px 16px 13px;
}

.nav-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4.25rem;
  padding: 0 2rem;
  box-shadow: 0 0 4px $box-shadow-color;
  background-color: $basic-white;
  z-index: 100;

  .MuiDrawer-paperAnchorLeft {
    left: 0;
    right: auto;
    width: 240px
  }

  .main-list {
    margin-top: 0;

    .MuiList-padding {
      padding-top: 0;
    }

    .MuiListItem-root {
      padding-top: 12px;
      padding-bottom: 13px;
    }
  }

  .MuiListItem-root.Mui-selected, .MuiListItem-button:hover, .MuiListItem-root.Mui-selected:hover {
    text-decoration: none;
    background-color: #DFEFF8;

    .basic-icon {
      color: #DFEFF8;
    }
  }

  .MuiListItem-root.Mui-focusVisible {
    background-color: rgba(153, 226, 255, 0.08);
  }

  .header-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    height: 100%;
    max-height: 68px;
    flex: 1;
    padding-left: 2%;

    a {
      height: 100%;
    }
  }

  .right-nav{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1em;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding-right: 1em;
  }

  @media screen and (min-width: 1235px) {
    box-shadow: none;

    &.job-search {
      left: auto;
      z-index: 250;
      width: 21%;
    }
  }
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 4.25rem auto 0;
  max-width: 1440px;
  height: calc(100% - 4.25rem);
}

.MuiListItem-button {
  @media screen and (min-width: 1024px) {
    &.Mui-selected .icon-box,
    &:hover .icon-box,
    &:hover .icon-box svg {
      opacity: 1;
    }
  }
}

.icon-box {
  color: $primary-gray;
  padding: 0em 1.5em 0em .5em;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  opacity: 0.7;
  svg {
    opacity: 0.6;
  }



  .MuiSvgIcon-root {
    width: 24px !important;
    height: 24px !important;
  }

  .personal-icon {
    color: $primary-black;
  }

  .MuiListItemText-root {
    color: #020606;
    padding: 6px 2px;


    span {
      font-size: .875rem;
    }
  }

  .MuiListItemIcon-root {
    min-width: 36px;
  }

  .MuiSvgIcon-root {
    width: .8em;
    height: 1em;
  }

  .basic-icon {
    color:white;
  }

  &:hover {
    cursor: pointer;
  }
}

.blue-underline {
  color: $primary-blue;
  position: relative;
}

.blue-nounderline {
  color: $primary-blue;
}

.filter-box {
  padding: 0.25em !important;
  background-color: $primary-light-blue;
}

.filter {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
  }
}

.red-circle {
  content: "";
  position: absolute;
  top: -3px;
  right: -3px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: $primary-red;
}

.logout {
  margin-top: 6px;

  @media screen and (max-width: 960px) {
    margin-right: 10px;
  }

  &:hover {
    cursor: pointer;
  }
}
