@import "../../../styles/variables";

.full-width {
  width: 100%;
}

.small-color-button, .color-button {
  border-radius: 20px 5px 20px 20px;

  font-weight: bold;
  background-image: $logo-gradient;
  text-transform: capitalize;
  outline: none;
  color: $primary-white;
  border: none;

  margin: 0.25em 0;
  cursor: pointer;
  text-shadow: 1px 1px 8px $box-shadow-color;
  max-width: 35rem;
}

.small-color-button {
  font-size: .825rem;
  padding: 0.5rem 1rem;
}

.color-button {
  padding: 0.5rem 2rem;
  font-size: 18px;
  font-weight: 400;


  a {
    text-decoration: none;
    color: white;
  }

  &:hover {
    @media only screen and (min-width: 1025px) {
      box-shadow: 0 3px 6px $box-shadow-color;
      filter: brightness(1.1);
      transition: all 0.2s ease-in-out;
    }
  }

  &:disabled {
    background-color: $primary-light-blue;
    cursor: default;
  }

  &:focus {
    outline: none;
  }
}

.next-step {
  background: $primary-blue;
  border-radius: 32px;
  text-shadow: none;
  transition: all .5s ease;
}

.inverse-button {
  background-color: $primary-white;
  color: $primary-blue;
  border-radius: 20px 5px 20px 20px;

  font-weight: bold;

  outline: none;

  border: none;
  padding: 0.5rem 2rem;
  margin: 0.25em 0;
  cursor: pointer;
  text-shadow: 1px 1px 8px $box-shadow-color;
  box-shadow: 0 3px 6px $box-shadow-color;
  max-width: 35rem;

  &:hover {
    filter: brightness(90%);
  }

  &:disabled {
    background-color: $primary-light-blue;
    cursor: default;
  }

  &:focus {
    border: 2px solid $primary-blue;
    outline: 2px solid $primary-white;
  }
}

.transparent-button {
  background-color: transparent;
  color: $primary-blue;
  border: none;
  outline: none;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 200;

  &:focus,
  &:hover,
  &:active {
    text-decoration: underline $primary-blue;
    text-underline-position: under;
  }

  &:disabled {
    color: $primary-lightest-gray;
    cursor: default;
    text-decoration: none;
  }
}

.link-button {
  background-color: transparent;
  border: none;
  outline: none;
  color: $primary-blue;

  &:focus,
  &:hover,
  &:active {
    text-decoration: underline $primary-blue;
    text-underline-position: under;
  }

  a {
    text-decoration: none;
    color: $primary-blue;
  }

  a:visited {
    color: $primary-blue;
  }

  &:disabled {
    color: $primary-lightest-gray;
    cursor: default;
    text-decoration: none;
  }
}

.add-button {
  border: none;
  outline: none;
  background-color: $basic-white;
  border-radius: 19px;
  box-shadow: 0 3px 6px $box-shadow-color;
  width: 100%;
  padding: 1.5em 2em;

  h3 {
    font-weight: 400;
  }

  &:focus {
    border: 2px solid $basic-white;
    outline: 2px solid $primary-blue;
  }

  .button-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    text-align: left;
  }
}

.add-tile-button {
  border: none;
  outline: none;
  background-color: $basic-white;
  width: 100%;
  padding: 1.5em 2em;
  border-radius: 8px;
  margin-bottom: 25px;

  h3 {
    font-weight: 600;
  }

  p {
    text-align: center;
    padding: 1em 0;
    color: #969696;
  }

  .button-content {
    display: block;
    width: 100%;
    height: 100%;
    text-align: left;

    .add-tile-content {
      text-align: center;
      margin-top: 2em;
    }

    .add-tile-icon {
      width: 55px !important;
      height: 55px !important;
    }

    h3 {
      color: $primary-black;
    }
  }
}

.save-button {
  font-size: 1.125rem;
  font-weight: 200;
  background-color: $primary-blue;
  text-transform: uppercase;
  outline: none;
  color: $basic-white;
  border: none;
  padding: 0 2.5rem;
  margin: 0.25em 0;
  cursor: pointer;
  max-width: 35rem;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 3em;
  p{
    color: white;
    font-size: 1.125rem;
    font-weight: bold;
  }

  a {
    text-decoration: none;
    color: white;
  }

  &:hover {
    background-color: rgba($primary-dark-blue, 0.8);
  }

  &:disabled {
    background-color: $primary-light-blue;
    cursor: default;
  }

  &:focus {
    border: 2px solid $basic-white;
    outline: 2px solid $primary-blue;
  }
}

.blue-outline-button {
  background: #fff;
  border: 2px solid $primary-blue;
  border-radius: 20px;
  color: $primary-blue;
  font-size: 18px;
  margin-right: 8px;
  min-width: 100px;
  padding: 8px;
  transition: all .5s ease;

  &:hover {
    background: $primary-blue;
    color: #fff;
  }
}


.close-btn  {
  background: none;
  border: none;
  padding: 6px 8px 2px;
  border-radius: 50%;
  @media screen and (min-width: 1024px) {
    &:hover {
      background: #e9e9e9;
      border: none;
      transition: all ease-in 0.2s
    }
  }
}

.btn-link-sm {
  @media screen and (max-width: 767px) {
    font-size: 15px;
    padding: 10px 15px;
    background: none;
    color: #008dd1;
    text-shadow: none;
  }
}
