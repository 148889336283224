@import "../../../styles/variables";

.signup-container-main {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;
  padding: 0;

  .signup-form {
    padding: 20px 45px;
  }

  @media only screen
    and (min-width: 768px)
    and (max-height: 1366px)
    and (orientation: portrait)
    and (-webkit-min-device-pixel-ratio: 1.5) {
      height: auto;
  }

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 3.25rem;
    height: calc(100% - 3.25rem);
}

  .padding-0-2p {
    padding: 0 2%;
  }

  .padding-0-5p {
    padding: 0 5%;
  }

  .padding-t-5p {
    padding-top: 5%;
  }

  .txt-center {
    text-align: center;
  }

  .MuiCircularProgress-root.dataLoading {
    color: $primary-blue;
  }

  .color-button {
    //border-radius: 32px;
    font-size: 18px;
    padding: 8px;
    width: 100%;

    &:hover {
      @media only screen and (min-width: 1025px) {
        box-shadow: 0 3px 6px $box-shadow-color;
        filter: brightness(1.1);
        transition: all 0.2s ease-in-out;
      }
    }
  }

  .previous-step {
    background: #fff;
    border: 1px solid #8d9292;
    text-shadow: none;
    color: #8d9292;
    transition: all .5s ease;

    &:hover {
      background: #8d9292;
      color: #fff;
    }
  }

  .signup-form-container {
    height: 100%;
    width: 100%;
    .input-icon {
      margin-bottom: 20px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #5D6161;
      border-radius: 2px;
    }

    legend.MuiFormLabel-root {
      color: #020606;
      line-height: 1.3;
    }


    p:not(.MuiFormHelperText-root.Mui-error) {
      padding-bottom: 16px;

      &.MuiFormHelperText-contained {
        font-size: 14px;
        margin: 0;
      }
    }

    .sign-up-with {
      position: relative;
      font-size: 14px;
      font-weight: normal;
      text-align: center;
      color: #B0B0B0;
      margin: 4% 1% 0 1%;

      &::before,
      &::after {
        position: absolute;
        content: "";
        top: 50%;
        border-top: 1px solid #E7E9EE;
        width: 35%;
        transform: translateY(-50%);
      }

      &::before {
        left: 2.5%;
      }
      &::after {
        right: 2.5%;
      }
    }

    .location-grid {
      position: relative;

      svg {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 58px;
        color: #9c9c9c;
      }

      .MuiAutocomplete-endAdornment {
        display: none;
      }
    }
  }

  .signup-info-container {
    display: none;
  }

  @media screen and (min-width: 768px) {
    .signup-form-container {
      align-items: center;
      background-color: $primary-blue;

      .signup-form {
        background-color: white;
        border-radius: 18px;
        //padding: 30px 0;
        margin-top: -2em;
        width: 70%;
      }
    }
  }

  @media screen and (min-width: 1025px) {
    flex-direction: row;

    .signup-form-container {
      width: 50%;
      align-items: center;
      background-color: $primary-blue;

      .signup-form {
        width: 85%;
        background-color: white;
        border-radius: 18px;
        margin-top: -2em;
      }
    }

    .signup-info-container {
      display: block;
      width: 50%;
      align-items: center;
      padding: 10px;
    }
  }

  @media screen and (min-width: 1350px) {
    height: auto;

    .signup-form-container {
      .signup-form {
        width: 80%;
      }
    }
  }
}

.required {
  font-size: 14px;

   &.alert {
    color: red;
   }
}
