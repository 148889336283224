.inner-frame {
    @media screen and (min-width: 1280px) {
        max-width: max-content !important;
    }
}

.user-container {
    @media screen and (min-width: 1280px) {
        &.MuiContainer-maxWidthLg {
            max-width: max-content !important;
        }
    }
}

.user-container-body {
    background: white;

    .row-email {
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .MuiTab-wrapper {
        font-family: Ubuntu;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: inherit;
        color: #008DD1;
    }

    .user-tabs {
        padding: 12px 0 !important;

        .tabs-container {
            padding: 0 1em;
        }
    
        .MuiTabs-indicator {
            background-color: #008DD1;
        }
    
        .candidate-tab, .pending-tab {
            padding-top: 2em;
        }
    }
}