@import "../../../../styles/variables";

.skills-container {
  width: 100%;
  max-width: $max-container-width;
  margin-bottom: 2rem;

  .alternatives-header{
    margin: .5em auto;
    text-align: center;
    width: 60%;
  }
}
.skills-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4.25rem;
  padding: 0 3.5em 0 1em;
  box-shadow: 0 3px 6px $box-shadow-color;
  background-color: $basic-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.8125rem;
  font-weight: bold;
  gap: 0.25em;
  color: $primary-lightest-gray;
}

.close-skills {
  position: fixed;
  top: 1em;
  right: 0.5em;
  z-index: 4;
}

.skills-content {
  margin-top: 4em;
}

.specialty-selection {
  margin-top: 1em;
  margin-bottom: 2em;
  width: 100%;
  h4 {
    color: $primary-dark-blue;
  }
}

.inprogress-skill {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2em 0;

  span {
    color: $primary-blue;
  }

  &:first-child {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -3em;
      right: -3em;
      border-top: 1px solid $primary-light-blue;
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -3em;
    right: -3em;
    border-top: 1px solid $primary-light-blue;
  }

  .edit-buttons {
    display: flex;
    align-items: center;
    gap: 1em;

    .download-buttons {
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      .refresh {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        font-size: 13.33px;
      }
    }
  }
}

.checklist-wrapper {
  .checklist {
    p {
      text-align: left;
    }

    .checklist-item {
      background-color: $primary-white;
      border-radius: 10px;
      margin: 1em 0;
    }
  }

  .category {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    h4 {
      text-transform: capitalize;
    }
  }
}
.in-progress {
  color: $primary-red;
  margin-left: 1ch;
}

