@import "../../../styles/variables";

.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  height: 100%;

  p {
    max-width: 50ch;
  }

  .welcome-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
    padding: 0 1em;

    .welcome-links {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1em;

      button:not(:first-child) {
        margin-top: 2em;
      }
    }
  }
}
