@import "../../styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  height: fit-content;
  min-height: 100vh;

  .top-logo {
    margin-top: 2.4375em;
    height: 4.5rem;
    width: 4.5rem;
    z-index: 2;

    @media screen and (min-width: 768px) {
      width: 100%;
      margin-top: 0;
    }
  }
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}
