@import "../../styles/variables";

.pre-apply {
  text-transform: uppercase;
  background-color: $background-light-gray;
  width: 100vw;
  padding: 0.5em 1em;
  margin-top: 2rem;
}

.job-summary {
  width: 100%;
  margin: 0.5em 0;
  .job-title {
    text-align: left;
  }

  .pay-disclaimer-container {
    margin-top: 20px;
  }
}

.apply-header {
  width: 100%;
  text-align: left;
  margin-top: 1em;
}
