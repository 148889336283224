@import "../../../styles/variables";

.search {
  .nav-header.job-search {
    background: transparent;
    z-index: 0;

    .right-nav {
      display: none;
    }
  }

  .main-content.nav-control {
    max-width: initial;
    margin-top: 0;
  }
}

.search-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  height: 100vh;

  @media screen and (min-width: 960px) and (max-width: 1234px) {
    .default-view div.mobile-filter-options {
      margin-top: 1em;
      padding-bottom: 12px;
    }
    .matched {
      #jobs-conatiner,
      #jobs-container + .job-details {
        max-height: calc(100vh - 58px);
      }
    }

  }

  .default-view {
    width: 100%;

  @media screen and (min-width: 1235px) {
    display: flex;
    flex-wrap: wrap;

    .explore {
      width: 100%;

      @media screen and (max-width: 1278px) {
        margin-left: 0;
      }
    }

    .matched {
      width: 100%;
      max-height: calc(100vh - 105px);
      overflow-y: hidden;

      .jobs, .jobs-container {
        align-items: flex-start;
        max-height: 100vh;
        overflow-y: scroll;
        padding: .75em;
        position: relative;

        .job-card {
          width: 100%;
        }

        .loading-wrap {
          background: rgba(0, 0, 0, 0.16);
          text-align: center;
          width: 100%;

          .MuiCircularProgress-indeterminate {
            margin: 3em auto;
          }
        }
      }
    }
  }

  .matched {
    .search-total {
      padding: 0.75em 0 1.5em 0em;
    }

    .filter-header {
      display: flex;
      width: 100%;

      .featured {
        width: 50%;
        align-self: center;
      }

      .filter-category {
        width: 50%;

        .MuiInput-underline:before, .MuiInput-underline:after {
          display: none;
        }

        &:first-of-type {
          width: 30%;
        }

        .MuiSelect-select.MuiSelect-select {
          font-size: .875rem;
          line-height: 1.25rem;
          color: $primary-blue;
          padding-left: 4px;
        }

        &.locations {
          .MuiAutocomplete-endAdornment {
            display: none;
          }
        }

        .MuiOutlinedInput-notchedOutline {
          border-radius: 0;
        }

        &:first-of-type {
          .MuiOutlinedInput-notchedOutline {
            border-radius: 10px 0 0 10px;
          }
        }

        .MuiAutocomplete-inputRoot,
        .MuiSelect-outlined {
          background: #fff;
          padding: 5px;
        }
      }
    }

    .MuiGrid-container {
      position: relative;
    }

    .MuiGrid-item.job-details {
      .back-arrow {
        background: $primary-white;
        cursor: pointer;
        display: none;
        font-size: 14px;
        padding-left: 10px;

        svg {
          margin-right: 10px;
          width: 16px;
        }
      }

      @media screen and (max-width: 959px) {
        height: 100vh;
        position: absolute;
        top: -2px;
        width: 100%;

        .back-arrow {
          display: flex;
          padding: 12px 10px;;
          align-items: center;
          margin-top: 10px;
        }
      }

      .layout-wrapper {
        height: auto;
        padding: 0;
      }

      .nav-header {
        display: none;
      }

      main {
        margin-top: 0;
        padding-left: 0;
        margin-top: 0;

        .jobs-detail-container {
          .info-container {
            display: none;
          }
        }

        .details-grid-container {
          flex-basis: 100%;
          max-width: 100%;
          padding-top: 0;

          .MuiButtonBase-root {
            display: none;
          }

          .job-image {
            margin-top: 0;
          }

          .details-wrapper {
            .save-button-sticky {
              position: fixed;
            }
          }
        }
      }
    }

    .MuiPagination-root {
      padding-bottom: 100px;

      .MuiPagination-ul {
        justify-content: flex-start;
      }
    }
  }


  .mobile-filter-svg {
    background: #fff;
    border: 1px solid $prim-blue;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    padding: 8px;
    cursor: pointer;
    position: relative;
    width: 42px;
    height: fit-content;

    svg {
      fill: $prim-blue !important;;
    }

    p {
      font-size: 16px;
      margin-left: 1em;
    }

    @media screen and (min-width: 1235px) {
      display: none;
    }
  }

  .mobile-filter-options {
    display: none;

    @media screen and (max-width: 1235px) {
      display: flex;
      margin-top: 5em;
      overflow: hidden;
      overflow-x: scroll;
      width: 100%;

      // .mobile-scroll-filters {
      //   align-items: center;

      //   .pill-blue {
      //     align-items: center;
      //     margin: 0px 5px 0 5px;

      //     p {
      //       width: max-content;
      //       color: #008dd1;
      //     }
      //   }

      //   .close {
      //     svg {
      //       padding-top: 6px;
      //       width: 0.75em;
      //       height: 1em;
      //     }
      //   }
      // }
    }
  }

  .desktop-search {
    background: #fff;
    box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.16);
    display: none;
    position: relative;
    width: 100%;
    z-index: 200;

    @media screen and (min-width: 1235px) {
      display: block;
    }
  }

  .search-bar {
    display: none;
    justify-content: flex-start;
    padding: 0 1em;
    background-color: white;
    padding-top: 1em;
    max-width: 1550px;

    @media screen and (min-width: 1235px) {
      display: flex;
    }

    .right-nav {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      width: -webkit-fill-available;
    }

    @media screen and (max-width: 960px) {
      .color-button {
        padding: .9em 2.5rem;
      }
    }

    .color-button {
      border-radius: 50px;
      margin: 0;
      max-height: 3em;
      padding: .8em 2.5rem;
      text-transform: capitalize;
      color: #fff;
      font-size: 16px;
    }

    // Dropdowns
    .MuiInputLabel-outlined.MuiInputLabel-marginDense {
      font-size: 14px;
      font-family: 'Ubuntu', sans-serif;
    }
    .explore-search-bar .MuiOutlinedInput-root {
      font-size: 14px;
      border-radius: 8px;
      width: 180px;
    }
    .explore-search-bar .MuiAutocomplete-root,
    .explore-search-bar .MuiFormControl-fullWidth {
      margin: 0;
      width: auto;
    }

    .explore-search-bar .w-full > .MuiFormControl-fullWidth {
      margin-right: 10px;
    }

    .css-yk16xz-control,
    .css-1pahdxg-control {
      font-size: 14px !important;
      border-radius: 8px !important;
      width: 225px !important;
      padding: 0 !important;
    }

    .explore-search-bar .MuiAccordion-root .MuiAccordionSummary-root {
      font-size: 14px;
      padding: 0 14px;
      border-radius: 8px;
    }
  }
}

  .jobs {
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 959px) {
      height: 70vh;
      overflow-y: auto;
      padding-top: 1em;
    }

    @media screen and (min-width: 960px) {
      padding-right: 1em;
    }
  }

  .subheader {
    margin: 0.5em 1em 0.75em;
    width: auto;

    .condensed-pill-section {
      max-width: 60%;

      .close {
        -webkit-box-align: center;
        align-items: center;
        display: flex;
      }

      svg {
        width: 0.55em;
        height: 0.65em;
        display: inline-block;
        fill: $primary-light-gray;
        stroke: $primary-light-gray;
        stroke-width: 1;
        -webkit-box-align: center;
        align-items: center;
        cursor: default;
        display: flex;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        justify-content: space-between;
      }
    }

    .MuiFormControl-fullWidth {
      display: flex;
      flex-direction: row;

      .sortby {
        color: $primary-gray;
        font-size: 18px;
        margin-right: 1em;
      }

      .MuiInputBase-root {
        min-width: 100px;
      }
    }

    .featured {
      margin-left: 2em;
      width: 50%;
    }
  }
}
.state-filters {
  display: flex;
  position: relative;
  gap: 2em;
  align-items: center;
  width: 100vw;
  padding: 0 3em 1em 2em;
  overflow-x: auto;

  .progress-explore {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media screen and (min-width: 1235px) {
    margin-left: 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(5rem, 6rem));
    overflow-y: auto;
    overflow-x: hidden;
    padding: .5em 0;
    margin: 1em 0;
    align-content: center;
    gap: 1em;

  }

  .location {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    width: max-content;

    @media screen and (min-width: 1235px) {
      width: 8rem;
    }

    p {
      margin-top: 0.25em;
      white-space: nowrap
    }

    &:hover {
      cursor: pointer;
    }
  }

  .location[disabled]{
    opacity: .2;
    cursor: initial;

  }

  .location-image {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.filtered-results {
  width: 100%;
  margin-top: 2em;

  p {
    text-align: center;
  }
  .back {
    position: absolute;
    top: 0;
    left: 0;

    &:hover {
      cursor: pointer;
    }
  }
}

.results{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .5em;
  p{
    font-size: 1rem;
    font-weight: bold;
  }
}

.filter-wrapper {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  padding: 1em 0;
  margin-bottom: 2em;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -3em;
    right: -3em;
    border-top: 1px solid $primary-light-blue;
  }


  .filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1em;

    p{
      background-color: $primary-light-blue;
      padding: .25em 1em;
      border-radius: 50px;
      font-size: .75rem;
    }

    span{
      margin-left: .5em;
      cursor: pointer;
    }
  }

  .filter-count{
    background-color: $primary-white;
    border-radius: 50px;
    border: 1px solid $primary-gray;
    padding: .25em .5em;
    min-width: 5rem;

  }


}

.MuiPagination-ul{
  justify-content: center;
}

.skeleton {
  .jobs {
    .MuiSkeleton-pulse {
      margin-bottom: 1em;
    }
  }
}

.filter-dialog {
  position: relative;
  height: 80%;
  margin-top: 4.25rem;
  .MuiBackdrop-root {
    margin-top: 4.25rem;
    height: 100%;
  }

  .MuiDialog-paper {
    overflow-y: hidden !important;
    height: 13rem !important;
  }

  .MuiDialog-scrollPaper {
    align-items: flex-start !important;
    justify-content: flex-end !important;
    margin-top: -1.125rem;

    &::before {
      content: "";
      border-style: solid;
      border-width: 10px 15px 10px 0;
      border-color: transparent $primary-light-blue transparent transparent;
      position: absolute;
      right: 2.7rem;
      top: -0.125rem;
      z-index: 1;
      transform: rotate(90deg);
    }
  }

  .MuiDialogContent-root {
    overflow-y: hidden !important;
  }

  .MuiPaper-root {
    width: 90%;
    height: 10rem;
    background-color: $primary-light-blue !important;
    position: relative;
    border-radius: 10px;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .MuiDialogTitle-root {
    padding: 1em 1em 0 1em !important;
  }

  .MuiTypography-h6 {
    font-family: "Ubuntu", sans-serif !important;
    font-size: 1.25rem !important;
    text-transform: uppercase;
    line-height: 1 !important;
  }

  .tip-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    label {
      color: $primary-blue;
      text-decoration: underline;
      text-transform: uppercase;
      margin-left: -1.25em;
      margin-right: 1em;
      font-weight: bold;
      font-size: 0.875rem;
      cursor: pointer;
    }
  }
}

.explore {
  background: #fff;
  justify-content: flex-start;
  align-items: baseline;
  flex-direction: column;

  .mobile {
    @media screen and (min-width: 1235px) {
      display: none;
    }
  }
}

.explore-search-bar {
  background: #fff;
  display: none;
  justify-content: flex-start;
  align-items: baseline;
  flex-direction: column;
  max-width: 875px;
  min-width: 800px;

  .accordion-wrap {
    display: flex;
  }

  .specialty-select {
    width: 100%;

    &.options {
      [class*="container"] {
        [class*="control"] {
          width: 100%;
          padding: .35em;
          overflow-y: scroll;
          height: 48px;


          [class*="IndicatorsContainer"] {
            width: 80px;
            justify-content: right;
            height: 35px;
            align-self: baseline;
          }

          [class*="indicatorContainer"] {
            position: fixed;
            width: 35px;
            margin-right: 35px;

            &:last-of-type {
              margin-right: 0;
            }
          }

          [class*="indicatorSeparator"] {
            height: 20px;
            position: fixed;
            margin-right: 35px;
            width: 1px;
          }

          .css-1hwfws3 {
            flex-wrap: nowrap;
            overflow-x: auto;

            [class*="multiValue"] {
              min-width: 100px;

              & > div {
                font-size: 75%;
                padding: 0;
              }
            }
          }
        }
      }
    }

    [class*="control"] {
      width: 100%;
      padding: 1px 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-radius: 50px;
      border-color: $primary-light-gray;

      .css-tlfecz-indicatorContainer {
        svg {
          color: $primary-light-gray;
        }
      }

      &:hover {
        border-color: $prim-black;
      }

      [class*="multiValue"] {
        border-radius: 5em;
        padding: .3em;

        :nth-child(2) {
          border-radius: 8em;
        }
      }
    }
  }

  .MuiOutlinedInput-root {
    border-radius: 50px;
    padding: 6px;
    border-color: $primary-light-gray;
  }

  .MuiAutocomplete-root {
    margin-right: 10px;

    &:hover {
      border-color: $primary-light-gray;
    }
  }

  .MuiFormControl-root {
    margin-right: 10px;

    &:hover {
      border-color: $primary-light-gray;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $primary-light-gray;

  }

  .MuiAccordion-root {
    background: none;
    box-shadow: none;
    width: 100%;

    &:before {
      display: none;
    }

    &.Mui-expanded {
      margin: 0;
    }

    .MuiAccordionSummary-root {
      background: #fff;
      border: 1px solid $primary-light-gray;
      border-radius: 50px;
      margin-right: .5em;
      min-height: 30px;

      &.Mui-expanded {
        min-height: auto;
      }

      .MuiIconButton-label {
        color: $primary-light-gray;

        .MuiSvgIcon-root {
          color: $primary-light-gray;
        }
      }

      .MuiButtonBase-root {
        padding: 7px 12px;

        &:hover {
          border-color: $prim-black;
        }
      }
    }

    .MuiAccordionSummary-content {
      margin: 5px 0;
      color: $primary-light-gray;

      &.Mui-expanded {
        margin: 5px 0;
      }
    }

    .MuiCollapse-container {
      background: $primary-white;
      position: absolute;
      width: 200px;
      z-index: 1;
    }
  }

  @media screen and (min-width: 1235px) {
    display: flex;
    flex-direction: row;

    .MuiAccordion-root {
      width: auto;
    }
  }

  .MuiCollapse-container {
    border-radius: 4px;
    border: 1px solid $background-gray;
    margin-top: 7px;
  }

  .MuiAccordionDetails-root {
    .date-range {
      width: 100%;

      .rdrDateRangePickerWrapper {
        position: absolute;
        width: auto;
        z-index: 1;
      }
    }
  }

  .mobile {
    @media screen and (min-width: 1235px) {
      display: none;
    }
  }
}

#jobs-container {
  @media screen and (min-width: 1235px) {
    max-width: 380px;
    & + .job-details {
      max-width: 100%;
      flex-grow: 1;
    }
  }
}
#jobs-container, #jobs-container-skeleton {
  border-right: solid 1px #ddd;
  padding-right: 10px;
  align-items: flex-start;
  max-height: calc(100vh - 105px);
  overflow-y: scroll;
  padding: .75em;
  position: relative;

  @media screen and (max-width: 959px) {
    overflow: hidden;
    overflow-y: scroll;
    border-right: none;
    max-height: calc(100vh - 100px)s;
  }
  & > div + div {
    border-top: 1px solid #e6e6e6;
  }
}

#jobs-container + .job-details {
  @media screen and (min-width: 959px) {
    max-height: calc(100vh - 105px);
    overflow-y: scroll;
  }
}

.job-card.condensed {
  @media screen and (min-width: 960px) {
    cursor: pointer;
    &:hover {
      background: #f5f5f5;
      text-decoration: underline;
    }
  }
}

.job-card.condensed.selected {
  background: white;
  border: 1px solid $prim-blue !important;
  border-radius: 12px;
  & + .job-card {
    border-top: none !important;
  }
}

.search-content {
  @media screen and (max-width: 959px) {
    padding: 0 15px;
  }
}
