@import "../../../styles/variables";

.myteam_container {
  padding: 16px;
  width: 100%;

  .myteam_header {
    h4 {
      margin-bottom: 1em;
      text-align: left;
    }
  }

  .team {
    &_paper {
      box-shadow: 0px 2px 4px rgba(2, 6, 6, 0.08);
      border-radius: 8px;
      display: flex;
      padding: .5em;
    }

    &_info {
      padding-left: .5em;
      width: 75%;
      word-break: break-all;

      p {
        margin: 4px 0;

        a {
          display: inline-block;

          &:hover {
            color: $prim-dark-blue;
            text-decoration: underline;
          }
        }
      }
    }

    &_position {
      color: $prim-gray;
      font-size: 12px;
      font-style: italic;
    }
  }
}
