@import "../../../../styles/variables";

.positions-header {
  font-weight: bold;
}

.work-form {
  width: 100%;
  max-width: $max-container-width;

  .edit-card{
    border: 1px solid $primary-light-gray;
    border-radius: 10px;
    padding: 0 2em 2em 2em;
  }
}

.work-details-card {
  border-radius: 5px;
}
