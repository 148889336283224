@import "../../../../styles/variables";

.verify-banner {
  position: relative;
  $border: 2px;
  width: 100%;
  max-width: $max-container-width;
  padding: 0.5em;
  background: $primary-white;
  background-clip: padding-box; /* !importanté */
  border: solid $border transparent; /* !importanté */
  border-radius: 5px;
  box-shadow: 0 3px 6px $primary-blue;
  margin: 0.25em auto;

  &:hover {
    cursor: pointer;
    box-shadow: none;
   
    transform: translateY(0.125rem);
    transition: 0.2s ease-in-out;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border; /* !importanté */
        border-radius: inherit; /* !importanté */
        background: $basic-white;
        border: solid $border $primary-light-blue; 
      }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: $logo-gradient;
  }


  

  .message {
    display: flex;
    gap: 0.25em;
    align-items: center;

    p {
      font-size: .85rem;
      color: $primary-blue;
    }
  }

  .action-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


//dialog styling

.verify-title{
    text-align: center;
}

.confirming {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.verify {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  padding-bottom: 1em;
}

.options {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 0.5em;
  }
}

.success {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 1em 0;

  .dialog-header{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 1em;
  }
  .close-dialog{
      cursor: pointer;
  }

  .verify-checkmark {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-image: $logo-gradient;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 6px $primary-blue;

    .verified-check {
      color: $primary-white;
      height: 3rem !important;
      width: 3rem !important;
    }
  }
}
