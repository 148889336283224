@import "../../../styles/variables";
.MuiDrawer-paper {
    .footer-profile-modal{
        position: absolute;
    }
    .modal-content {
        .back {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        
          }
    }
}

.MuiDrawer-paperAnchorRight {
    min-width: 450px;
    
    @media only screen and (max-width: 960px) {
        left: auto;
        right: 0;
        width: 100%;
        min-width: 300px;
      }
}

.profile {
  width: 100%;
  justify-content: flex-start;
  background-color: $basic-white;
  position: relative;
  overflow-x: hidden;

  .spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 3.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .MuiCircularProgress-colorPrimary {
      color: $primary-blue;
    }
    p {
      margin-top: 1em;
      text-align: center;
      font-weight: 600;
      font-size: 1rem;
    }
  }

  h3 {
    color: $primary-black;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2em;
  }
}